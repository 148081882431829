import { Fragment, useState } from "react";
import { Tabs, Tab, Paper } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import PropTypes from "prop-types";

import Couriers from "./Couriers";

import { ASSIGNED, UNASSIGNED } from "../../data/constants";

import styles from "./CourierTabs.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
}));

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={value === index ? styles.layoutGrid : styles.layoutHidden}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MiniTab: any = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 0,
    maxWidth: 80,
    padding: 0,
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up(992)]: {
      'font-size': '1rem',
      'margin-left': 32,
      'margin-right': 32,
      },
    [theme.breakpoints.down(992)]: {
      'font-size': '0.8rem',
      'margin-left': 16,
      'margin-right': 16,
    }
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function CourierTabs({ highlightCourier, highlightedCourierId }: any) {
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <div className={styles.courierTabs}>
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Couriergame tabs"
        >
          <MiniTab label="All" {...a11yProps(0)} />
          <MiniTab label="Idle" {...a11yProps(1)} />
          <MiniTab label="Busy" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Couriers
            highlightedCourierId={highlightedCourierId}
            highlightCourier={highlightCourier}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Couriers
            highlightedCourierId={highlightedCourierId}
            highlightCourier={highlightCourier}
            status={UNASSIGNED}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Couriers
            highlightedCourierId={highlightedCourierId}
            highlightCourier={highlightCourier}
            status={ASSIGNED}
          />
        </TabPanel>
      </Paper>
    </div>
  );
}

export default CourierTabs;
