import { useContext } from "react";

import { Button } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { GlobalContext } from "../../../context/reducers/globalReducer";

import { PLAYING } from "../../../data/constants";

import styles from "./CustomersRow.module.css";

function CustomersRow({
  customer,
  highlightCustomer,
  addCustomer,
  highlightedCustomer,
}: any) {
  const { state, } = useContext(GlobalContext);

  const highlight = (): any => {
    highlightCustomer(customer.id);
  };

  const add = (): any => {
    addCustomer(customer.id);
  };

  let classNames = [];

  if (highlightedCustomer && highlightedCustomer.id === customer.id) {
    classNames.push(styles.customerHighlighted);
  }

  return (
    <TableRow
      key={customer.id}
      className={classNames.join(" ")}
      onMouseEnter={highlight}
    >
      <TableCell size="small">{customer.name}</TableCell>
      <TableCell size="small">
        <Button onClick={add} size="small" disabled={customer.added || state.gameState !== PLAYING}>
          Add
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default CustomersRow;
