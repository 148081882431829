import { useContext, Fragment } from "react";
import JobMarker from "./JobMarker";

import { GlobalContext } from "../context/reducers/globalReducer";
import { UNASSIGNED } from "../data/constants";

function JobMarkers({
  highlightedJobId,
  highlightedCourierId,
  assignJob,
  isPlaying
}: any) {
  const { state } = useContext(GlobalContext);
  const { jobs } = state;

  const unassignedJobs = jobs.filter((job: Job) => {
    return job.status === UNASSIGNED;
  });

  return (
    <Fragment>
      {unassignedJobs.map((job: any) => {
        return (
          <JobMarker
            job={job}
            highlightedJobId={highlightedJobId}
            assignJob={assignJob}
            highlightedCourierId={highlightedCourierId}
            key={job.id}
            isPlaying={isPlaying}
          />
        );
      })}
    </Fragment>
  );
}

export default JobMarkers;
