import { Typography } from "@material-ui/core";

import styles from "./HowToPlayPage.module.css";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  h: {
    'font-size': '1.2rem'
  },
  he: {
    'font-size': '1.1rem',
    'margin-bottom': '20px'
  },
});

function HowToPlayPage() {
  const classes = useStyles();

  return <div className={styles.page}>
    <h3><Typography className={classes.h}>How to play</Typography></h3>
    <header>
      <Typography className={classes.he}>You're the Controller at a courier company in London. Organise deliveries of items and make as much money as possible by day's end, 5pm.</Typography>
    </header>
    <article>
      <Typography>To start, click on 'Play' in the top right hand corner. A menu will pop down. Choose a speed to play at. 'Silly' will play the game greatly speeded up, which is a simple way to try things out to start with.</Typography>
      <p />
      <Typography>When the game starts some couriers will appear in the left hand column and some jobs to complete in the right hand column.</Typography>
      <p />
      <Typography>Hover over a courier to select them, or press the key that their name starts with, e.g. press a to highlight Agnetha, b for Bob etc.</Typography>
      <p />
      <Typography>Then click 'Assign' next to one of the jobs to assign it to that courier.</Typography>
      <p />
      <Typography>The job is now assigned to the courier. You'll see the courier travel to pick the item up then take it to its destination. You can drag the map around and zoom in and out.</Typography>
      <p />
      <Typography>When the job is complete the earnings will be added to your total for the day (if any - some jobs are loss leaders).</Typography>
      <p />
      <Typography>Customers will add more jobs throughout the day.</Typography>
      <p />
      <Typography>Some jobs have a time limit for delivery. They show up in orange. If not delivered in time you'll be fined.</Typography>
      <p />
      <Typography>You can choose more couriers and customers by clicking on the Couriers and Customers tabs.</Typography>
      <p />
      <Typography>The more couriers you have the more you can deliver, but you have to pay them!</Typography>
      <p />
      <Typography>The way to make the most money is to assign multiple jobs to a courier. You can then rearrange the order they pick up and drop off items by dragging them left and right at the bottom of the screen. The shorter their route the more money you can make!</Typography>
      <p />
      <Typography>Bikes are faster but can hold less.</Typography>
      <p />
      <Typography>Make sure all the items you've assigned to a courier will fit in their vehice or they'll abandon any job they can't carry when they arrive to pick it up.</Typography>
      <p />
      <Typography>Treat your couriers well or they'll get upset!</Typography>
      <p />
      &nbsp;
      <p />
      &nbsp;
    </article>
  </div>;
}

export default HowToPlayPage;
