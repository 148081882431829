import { useContext, useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import CustomerDetail from "./CustomerDetail";
import CustomersRow from "./rows/CustomersRow";

import { actionTypes } from "../../context/actions/globalActions";

import styles from "./CustomersPage.module.css";

import { GlobalContext } from "../../context/reducers/globalReducer";

import { Typography } from "@material-ui/core";

function CustomersPage() {
  const { state, dispatchToGlobal } = useContext(GlobalContext);
  const { customers } = state;

  const [highlightedCustomer, setHighlightedCustomer] = useState<Customer>();

  const highlightCustomer = (customerId: string) => {
    const customer = customers.find((customer: Customer) => {
      return customer.id === customerId;
    });

    if (customer) {
      setHighlightedCustomer(customer);
    }
  };

  const addCustomer = (customerId: string): any => {
    dispatchToGlobal({ type: actionTypes.ADD_CUSTOMER, customerId });
  };

  return (
    <div className={styles.customers}>
      <div className={styles.customersRows}>
        <Table>
          <TableBody>
            {customers.map((customer: Customer) => (
              <CustomersRow
                key={customer.id}
                customer={customer}
                addCustomer={addCustomer}
                highlightCustomer={highlightCustomer}
                highlightedCustomer={highlightedCustomer}
              />
            ))}
          </TableBody>
        </Table>
        {!customers.length && (<div className={styles.startGame}><Typography>Start a game to see the customers.</Typography></div>)}
      </div>
      <div className={styles.customersPage}>
        {highlightedCustomer && (
          <CustomerDetail highlightedCustomer={highlightedCustomer} />
        )}
      </div>
    </div>
  );
}

export default CustomersPage;
