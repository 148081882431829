import { Fragment, useContext, useEffect, useState } from "react";

// @todo - readd AppBar?
import { Typography } from "@material-ui/core";
import { VolumeUpOutlined, VolumeOffOutlined } from "@material-ui/icons";

import PropTypes from "prop-types";

import Map from "./panels/Map";
import CourierTabs from "./panels/CourierTabs";
import JobTabs from "./panels/JobTabs";
import Schedule from "./panels/Schedule";
import CustomersPage from "./Pages/CustomersPage";
import CouriersPage from "./Pages/CouriersPage";
import HowToPlayPage from "./Pages/HowToPlayPage";
import PrivacyPage from "./Pages/PrivacyPage";

import Play from "./Play";

import styles from "./Layout.module.css";

import { GlobalContext } from "../context/reducers/globalReducer";

import { actionTypes } from "../context/actions/globalActions";

import { PICK_UP, DROP_OFF, GAME_OVER, PAUSED, getTimeFromTicks, decodePolyline } from "../data/constants";

import { waypoints } from "../data/waypoints";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  breakpointFont: {
    [theme.breakpoints.up(992)]: {
      'font-size': '1rem'
    },
    [theme.breakpoints.down(992)]: {
      'font-size': '0.8rem'
    }
  },
  breakpointFontBold: {
    [theme.breakpoints.up(992)]: {
      'font-size': '1rem',
      'font-weight': 'bold'
    },
    [theme.breakpoints.down(992)]: {
      'font-size': '0.8rem',
      'font-weight': 'bold'
    }
  },
  breakpointFontPointer: {
    [theme.breakpoints.up(992)]: {
      'font-size': '1rem',
      'cursor': 'pointer'
    },
    [theme.breakpoints.down(992)]: {
      'font-size': '0.8rem',
      'cursor': 'pointer'
    }
  },
}));

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={value === index ? styles.layoutGrid : styles.layoutHidden}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const fetchDirections = async (from: any, to: any) => {
  const location = `start=${from[0]},${from[1]}&end=${to[0]},${to[1]}`;

  if (waypoints[location]) {
    return waypoints[location];
  }

  let filename = `${from[0]}_${from[1]}_${to[0]}_${to[1]}`

  filename = filename.replace(/\D/g, '_') + '.txt';

  const response = await fetch(
      `geos/${filename}`
  );

  if (!response.ok) {
    throw new Error(`Couldn't fetch file ${filename}`);
  }

  const polystring = await response.text();

  if (polystring.match("DOCTYPE")) {
    console.log('FROM');
    console.log(from);
    console.log('TO');
    console.log(to);
    throw new Error("Non-404 failure to fetch polyline file");
  }

  waypoints[location] = {
    "type": "LineString",
  };

  let wps: any = decodePolyline(polystring);

  // Lat and long are back to front with polylines compared to direction fetch

  wps = wps.map((wp: any) => {
    return [wp[1], wp[0]];
  })

  waypoints[location].coordinates = wps

  // Calculate distances
  const distances = [0];
  let totalDistance = 0;

  let fromCoordinates = wps[0];

  for (let i = 1; i < wps.length; i++) {
    const toCoordinates = wps[i];

    const ns = Number(fromCoordinates[0]) * 10000 - Number(toCoordinates[0]) * 10000;
    const ew = Number(fromCoordinates[1]) * 10000 - Number(toCoordinates[1]) * 10000;

    const distance = Math.ceil(Math.sqrt(ns * ns + ew * ew));

    totalDistance += distance;

    distances.push(totalDistance);

    fromCoordinates = toCoordinates;
  }

  waypoints[location].distances = distances;

  return waypoints[location];
}

const addNewDirections = (
  from: any,
  to: any,
  end: any,
  jobId: string,
  courierId: string,
  dispatchToGlobal: any
) => {
  dispatchToGlobal({
    type: actionTypes.SET_DIRECTIONS,
    from,
    to,
    courierId,
    pickUpOrDropOff: PICK_UP,
    jobId,
  });

  dispatchToGlobal({
    type: actionTypes.SET_DIRECTIONS,
    from: to,
    to: end,
    courierId,
    pickUpOrDropOff: DROP_OFF,
    jobId,
  });
};

function Layout() {
  const { state, dispatchToGlobal } = useContext(GlobalContext);
  const { couriers, jobs, GeoJSONUpdateRequired } = state;

  const classes = useStyles();

  useEffect(() => {
    const doGeoJSONCalls = () => {
      couriers.forEach((courier: Courier) => {
        courier.directions.forEach((dir: any) => {
          if (!dir.GeoJSONRequested) {
            // Fetch the GeoJSON for these directions
            dispatchToGlobal({
              type: actionTypes.GEO_JSON_REQUESTED,
              courierId: courier.id,
              directionsId: dir.id,
            });

            let prom;

            prom = fetchDirections(dir.from, dir.to);

            prom.then((res) => {
              dispatchToGlobal({
                type: actionTypes.POPULATE_GEOJSON,
                courierId: courier.id,
                directionsId: dir.id,
                GeoJSON: res
              });
            }).catch((error) => {
              // console.log(`Can't fetch waypoints, removing job: ${error}`);

              // @todo - remove this job from the courier
              dispatchToGlobal({
                type: actionTypes.REMOVE_FAILED_JOB,
                courierId: courier.id,
                directionsId: dir.id,
              });
            });
          }
        });
      });
    };

    if (GeoJSONUpdateRequired) {
      doGeoJSONCalls();
    }
  }, [GeoJSONUpdateRequired, couriers, dispatchToGlobal]);

  const [highlightedJobId, setHighlightedJobId] = useState("");
  const [highlightedScheduledJob, setHighlightedScheduledJob] = useState([
    "",
    "",
  ]);
  const [highlightedCourierId, setHighlightedCourierId] = useState('');

  const [value, setValue] = useState(0);

  const highlightCourier = (courierId: string) => {
    setHighlightedCourierId(courierId);
  };

  const highlightJobId = (jobId: string) => {
    setHighlightedJobId(jobId);
  };

  const highlightScheduledJob = (jobId: string, podo: string) => {
    setHighlightedScheduledJob([jobId, podo]);
  };

  const toggleMute = () => {
    dispatchToGlobal({
      type: actionTypes.TOGGLE_MUTE
    });
  }

  const showHowToPlay = () => {
    setValue(3);
  }

  const showPrivacy = () => {
    setValue(4);
  }

  const assignJob = (jobId: string, courierId: string): any => {
    const courier = couriers.find((courier: Courier) => {
      return courier.id === courierId;
    });
    const job = jobs.find((job: Job) => {
      return job.id === jobId;
    });

    let from;

    if (courier && job) {
      if (courier.directions.length) {
        // Take start position as coordinates on end of direction
        const finalDirections =
          courier.directions[courier.directions.length - 1];

        from = finalDirections.to;
      } else {
        from = courier.coordinates;
      }

      let to = job.from.coordinates;
      let end = job.to.coordinates;

      addNewDirections(from, to, end, job.id, courier.id, dispatchToGlobal);
    }
  };

  const unassignJob = (jobId: string): any => {
    dispatchToGlobal({
      type: actionTypes.UNASSIGN_JOB,
      jobId,
    });
  };

  let time = "GAME OVER";

  if (state.gameState === PAUSED) {
    time = "PAUSED";
  } else if (state.gameState !== GAME_OVER) {
    time = getTimeFromTicks(state.tick);
  }

  const showMap = () => {
    setValue(0);
  }
  const showCouriers = () => {
    setValue(1);
  }
  const showCustomers = () => {
    setValue(2);
  }

//  <Tabs value={value} onChange={handleChange} aria-label="Couriergame tabs" className={styles.tabs}>

  return (
    <div className={styles.layoutOuterGrid}>
      <div className={styles.tabSpread}>
        <div className={styles.layoutStats}>
          <div {...a11yProps(0)} className={styles.layoutStatsBoxesClickable}>
            <Typography className={classes.breakpointFont} onClick={showMap}>
              MAP
            </Typography>
          </div>
          <div {...a11yProps(1)} className={styles.layoutStatsBoxesClickable}>
            <Typography className={classes.breakpointFont} onClick={showCouriers}>
              COURIERS
            </Typography>
          </div>
          <div {...a11yProps(2)} className={styles.layoutStatsBoxesClickable}>
            <Typography className={classes.breakpointFont} onClick={showCustomers}>
              CUSTOMERS
            </Typography>
          </div>
          <div className={styles.layoutStatsBoxes}><Typography className={classes.breakpointFont}><span dangerouslySetInnerHTML={{__html: time}} /></Typography></div>
          <div className={styles.layoutStatsBoxes}><Typography className={classes.breakpointFontBold}>{"£" + (state.money / 100).toFixed(2)}</Typography></div>
          <div className={styles.layoutStatsBoxes}><Typography className={classes.breakpointFont}>JOBS&nbsp;DONE:&nbsp;{state.noJobsCompleted}</Typography></div>
          <div className={styles.sound} onClick={toggleMute}>{state.playSounds ? (<VolumeUpOutlined></VolumeUpOutlined>) : (<VolumeOffOutlined></VolumeOffOutlined>)}</div>
        </div>
        <Play showHowToPlay={showHowToPlay} showMap={showMap} />
      </div>
      <TabPanel value={value} index={0}>
        <CourierTabs
          highlightCourier={highlightCourier}
          highlightedCourierId={highlightedCourierId}
        />
        <Map
          highlightedJobId={highlightedJobId}
          highlightedCourierId={highlightedCourierId}
          highlightCourier={highlightCourier}
          highlightedScheduledJob={highlightedScheduledJob}
          assignJob={assignJob}
          unassignJob={unassignJob}
        />
        <JobTabs
          highlightJobId={highlightJobId}
          highlightedJobId={highlightedJobId}
          highlightedCourierId={highlightedCourierId}
          assignJob={assignJob}
          unassignJob={unassignJob}
        />
        <Schedule
          highlightedCourierId={highlightedCourierId}
          highlightScheduledJob={highlightScheduledJob}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CouriersPage />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CustomersPage />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <HowToPlayPage />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <PrivacyPage />
      </TabPanel>
      <div className={styles.privacy}>
        <Typography className={classes.breakpointFont}>&copy; <a href="https://twitter.com/beigesoft">Beigesoft</a> 2023&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a onClick={showPrivacy} className={classes.breakpointFontPointer}>Privacy</a></Typography>
      </div>
    </div>
  );
}

export default Layout;
