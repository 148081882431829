import * as L from "leaflet";

export const ZOOM: number = 14;
export const MIN_ZOOM: number = 12;
export const MAX_ZOOM: number = 14;
export const LONG: number = -0.12973;
export const LAT: number = 51.51611;

// --lat=51.31611:51.71611 --lon=-0.02027:-0.27973 --zoom=11:18 --dumptilelist=foo.txt
// ./downloadosmtiles.pl --link='https://www.openstreetmap.org/?lat=51.51611&lon=-0.12973&zoom=12&layers=B000FTF' --zoom=11:18 --dumptilelist=foo.txt

const EW_BD: number = 0.2;
const NS_BD: number = 0.15;

export const BOUNDS = [
  [LAT - NS_BD, LONG - EW_BD],
  [LAT + NS_BD, LONG + EW_BD],
];

export const BIKE = "Bike";
export const BIKE_LARGE_TOP = "Bike large top";
export const SMALL_VAN = "Small van";
export const MEDIUM_VAN = "Transit";
export const LARGE_VAN = "Luton";

export const BIKE_VOLUME = 30;
export const BIKE_MASS = 30;
export const BIKE_LARGE_TOP_VOLUME = 50;
export const BIKE_LARGE_TOP_MASS = 50;
export const SMALL_VAN_VOLUME = 5000;
export const SMALL_VAN_MASS = 1000;
export const MEDIUM_VAN_VOLUME = 15000;
export const MEDIUM_VAN_MASS = 1500;
export const LARGE_VAN_VOLUME = 20000;
export const LARGE_VAN_MASS = 3000;

export const UNASSIGNED: string = "unassigned";
export const ASSIGNED: string = "assigned";
export const IN_PROGRESS: string = "in progress";

export const TOO_BIG = "Job too big for courier";

export const PICK_UP = "PICK_UP";
export const DROP_OFF = "DROP_OFF";

export const L_MARKER_SHADOW_URL =
  "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png";
export const L_ICON_SIZE_SMALL: L.PointExpression = [25, 41];
export const L_ICON_ANCHOR_SMALL: L.PointExpression = [12, 41];
export const L_POPUP_ANCHOR_SMALL: L.PointExpression = [1, -34];
export const L_SHADOW_SIZE_SMALL: L.PointExpression = [41, 41];

export const L_ICON_SIZE_LARGE: L.PointExpression = [37, 55];
export const L_ICON_ANCHOR_LARGE: L.PointExpression = [16, 55];
export const L_POPUP_ANCHOR_LARGE: L.PointExpression = [2, -43];
export const L_SHADOW_SIZE_LARGE: L.PointExpression = [55, 55];

export const NOT_STARTED = 'Not started';
export const PLAYING = 'Playing';
export const PAUSED = 'Paused';
export const GAME_OVER = 'Game over';

// Number of ticks per minute in real time mode
export const MIN_MULTIPLIER = 4;

export const MAX_TICKS = 480 * MIN_MULTIPLIER;

export const REAL_TIME_TICK_SPEED = Math.floor(60000 / MIN_MULTIPLIER);
export const FAST_TICK_SPEED = Math.floor(7500 / MIN_MULTIPLIER);
export const SILLY_TICK_SPEED = Math.floor(1250 / MIN_MULTIPLIER);

export const LARGE_CUSTOMER_JOB_NO_LIMIT = 6;
export const MEDIUM_CUSTOMER_JOB_NO_LIMIT = 4;

export const getTimeFromTicks = (tick: number) => {
  let time: string = "";

  let hour = Math.floor(Math.floor(tick / MIN_MULTIPLIER) / 60);

  if (tick >= (240 * MIN_MULTIPLIER)) {
    hour -= 3;
  } else {
    hour += 9;
  }

  let minute = (Math.floor(tick / MIN_MULTIPLIER) % 60).toString();

  minute = minute.padStart(2, "0");

  let ampm = 'PM';

  if (tick < 180 * MIN_MULTIPLIER) {
    ampm = "AM";
  }

  time = `${hour}:${minute}&nbsp;${ampm}`;

  return time;
}

export const decodePolyline: any = (encodedPolyline: any, includeElevation: any) => {
  let points = []
  let index = 0
  const len = encodedPolyline.length
  let lat = 0
  let lng = 0
  let ele = 0
  while (index < len) {
    let b
    let shift = 0
    let result = 0
    do {
      b = encodedPolyline.charAt(index++).charCodeAt(0) - 63 // finds ascii
      // and subtract it by 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)

    lat += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1))
    shift = 0
    result = 0
    do {
      b = encodedPolyline.charAt(index++).charCodeAt(0) - 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)
    lng += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1))

    if (includeElevation) {
      shift = 0
      result = 0
      do {
        b = encodedPolyline.charAt(index++).charCodeAt(0) - 63
        result |= (b & 0x1f) << shift
        shift += 5
      } while (b >= 0x20)
      ele += ((result & 1) !== 0 ? ~(result >> 1) : (result >> 1))
    }
    try {
      let location = [(lat / 1E5), (lng / 1E5)]
      if (includeElevation) location.push((ele / 100))
      points.push(location)
    } catch (e) {
      console.log(e)
    }
  }
  return points
}