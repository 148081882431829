import { Fragment } from "react";
import * as L from "leaflet";

import { Marker, Popup } from "react-leaflet";

import styles from "./CourierMarker.module.css";

import {
  L_MARKER_SHADOW_URL,
  L_ICON_SIZE_SMALL,
  L_ICON_ANCHOR_SMALL,
  L_POPUP_ANCHOR_SMALL,
  L_SHADOW_SIZE_SMALL,
  L_ICON_SIZE_LARGE,
  L_ICON_ANCHOR_LARGE,
  L_POPUP_ANCHOR_LARGE,
  L_SHADOW_SIZE_LARGE,
} from "../data/constants";

const blueIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-blue.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_SMALL,
  iconAnchor: L_ICON_ANCHOR_SMALL,
  popupAnchor: L_POPUP_ANCHOR_SMALL,
  shadowSize: L_SHADOW_SIZE_SMALL,
});

const blueBigIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-blue.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_LARGE,
  iconAnchor: L_ICON_ANCHOR_LARGE,
  popupAnchor: L_POPUP_ANCHOR_LARGE,
  shadowSize: L_SHADOW_SIZE_LARGE,
});

function CourierMarker({
  highlightCourier,
  courier,
  highlightedCourierId,
  isPlaying
}: any) {
  const highlight = (): any => {
    highlightCourier(courier.id);
  };

  return (
    <Marker
      position={[courier.coordinates[1], courier.coordinates[0]]}
      icon={courier.id === highlightedCourierId ? blueBigIcon : blueIcon}
    >
      <Popup>
        {courier.id !== highlightedCourierId ? (
          <Fragment>
            {courier.name} {isPlaying && (<a onClick={highlight} className={styles.select}>select</a>)}
          </Fragment>
        ) : (
          courier.name
        )}
      </Popup>
    </Marker>
  );
}

export default CourierMarker;
