import React, { useContext } from "react";

import {
    Box,
    Menu,
    MenuItem,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    useMediaQuery,
    Typography
} from "@material-ui/core/";

import Fade from "@material-ui/core/Fade";

import { GlobalContext } from "../context/reducers/globalReducer";

import { actionTypes } from "../context/actions/globalActions";

import {
    PLAYING,
    PAUSED,
    GAME_OVER,
    NOT_STARTED,
    REAL_TIME_TICK_SPEED,
    FAST_TICK_SPEED,
    SILLY_TICK_SPEED
} from "../data/constants";

import { makeStyles, useTheme } from "@material-ui/core/styles";

import { tii } from "../data/tii";

const useStyles = makeStyles((theme) => ({
    rightAlignButton: {
        'margin': '12px',
        'margin-right': '20px'
    },
    alignMenu: {
        'margin-top': '34px',
        'margin-left': '18px'
    },
    breakpointFont: {
        [theme.breakpoints.up(992)]: {
          'font-size': '1rem',
          'margin': '14px',
          'cursor': 'pointer'
        },
        [theme.breakpoints.down(992)]: {
          'font-size': '0.8rem',
          'margin': '14px',
          'cursor': 'pointer'
        }
    }
}));

function Play({ showHowToPlay, showMap }: any) {    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { state, dispatchToGlobal } = useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  
    const classes = useStyles();

    const handleClickOpen = () => {
        setAnchorEl(null);

        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const startTicking = (rate: number) => {
        dispatchToGlobal({
            type: actionTypes.START
        });

        if (tii.tii) {
            // Already running, end
            clearInterval(tii.tii);
        }

        const tickIntervalId = setInterval(() => {
            dispatchToGlobal({
                type: actionTypes.TICK,
            });
        }, rate);
    
        tii.tii = tickIntervalId;
    }
    
    const openMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    
    const closeMenu = () => {
        setAnchorEl(null);
    };
        
    const quit = () => {
        setAnchorEl(null);

        dispatchToGlobal({
            type: actionTypes.END_GAME
        });

        setOpen(false);
    }

    const play = () => {
        setAnchorEl(null);

        startTicking(REAL_TIME_TICK_SPEED);

        showMap();
    };

    const playFast = () => {
        setAnchorEl(null);

        startTicking(FAST_TICK_SPEED);

        showMap();
    };

    const playSilly = () => {
        setAnchorEl(null);

        startTicking(SILLY_TICK_SPEED);

        showMap();
    };

    const pause = () => {
        setAnchorEl(null);

        dispatchToGlobal({
            type: actionTypes.PAUSE,
        });
    };

    const unpause = () => {
        setAnchorEl(null);

        dispatchToGlobal({
            type: actionTypes.UNPAUSE,
        });
    };

    const doShowHowToPlay = () => {
        setAnchorEl(null);

        showHowToPlay();
    }

    let buttons = [
        <MenuItem onClick={doShowHowToPlay} key="doShowHowToPlay">How to Play</MenuItem>
    ];

    let playButtonText = "PLAY";

    if (state.gameState === NOT_STARTED || state.gameState === GAME_OVER) {
        buttons.unshift(<MenuItem onClick={playSilly} key="playSilly">Play (Silly - 10 mins)</MenuItem>);
        buttons.unshift(<MenuItem onClick={playFast} key="playFast">Play (Fast - 1 hour)</MenuItem>);
        buttons.unshift(<MenuItem onClick={play} key="play">Play (Real time - 8 hours)</MenuItem>);
    } else if (state.gameState === PLAYING) {
        buttons.unshift(<MenuItem onClick={handleClickOpen} key="quit">Quit</MenuItem>);
        buttons.unshift(<MenuItem onClick={pause} key="pause">Pause</MenuItem>);
        playButtonText = "PAUSE";
    } else if (state.gameState === PAUSED) {
        buttons.unshift(<MenuItem onClick={handleClickOpen} key="quit">Quit</MenuItem>);
        buttons.unshift(<MenuItem onClick={unpause} key="unpause">Unpause</MenuItem>);
        playButtonText = "UNPAUSE";
    }

    return <>
        <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure you want to quit?"}
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={quit}>
            Yes
          </Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
        <Box display="flex" justifyContent="flex-end" className={classes.rightAlignButton}>
            <Typography><a className={classes.breakpointFont} onClick={openMenu}>{playButtonText}</a></Typography>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              TransitionComponent={Fade}
              className={classes.alignMenu}
            >
                {buttons}
            </Menu>
        </Box>
        </>;
}

export default Play;
