import { useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import * as L from "leaflet";

import { GlobalContext } from "../../context/reducers/globalReducer";

import CourierMarkers from "../CourierMarkers";
import JobMarkers from "../JobMarkers";
import ScheduleRoute from "../ScheduleRoute";

import {
  ZOOM,
  LAT,
  LONG,
  BOUNDS,
  MIN_ZOOM,
  MAX_ZOOM,
  L_MARKER_SHADOW_URL,
  L_ICON_SIZE_SMALL,
  L_ICON_ANCHOR_SMALL,
  L_POPUP_ANCHOR_SMALL,
  L_SHADOW_SIZE_SMALL,
  PLAYING,
} from "../../data/constants";

import styles from "./Map.module.css";

const greyIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-grey.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_SMALL,
  iconAnchor: L_ICON_ANCHOR_SMALL,
  popupAnchor: L_POPUP_ANCHOR_SMALL,
  shadowSize: L_SHADOW_SIZE_SMALL,
});

let latLngBounds: L.LatLngBounds = L.latLngBounds(
  L.latLng(Number(BOUNDS[0][0]), Number(BOUNDS[0][1])),
  L.latLng(Number(BOUNDS[1][0]), Number(BOUNDS[1][1]))
);

function Map({
  highlightedJobId,
  highlightedCourierId,
  highlightedScheduledJob,
  highlightCourier,
  assignJob,
}: any) {
  const { state } = useContext(GlobalContext);
  const { couriers, jobs } = state;

  const ENABLED = true;

  let highlightedCourier: Courier | undefined;

  highlightedCourier = couriers.find((courier: Courier) => {
    return courier.id === highlightedCourierId;
  });

  const isPlaying = state.gameState === PLAYING;

// url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
// url="./tiles/{z}/{x}/{y}.png"

  return (
    <div className={styles.map}>
      {ENABLED && (
        <MapContainer
          center={[Number(LAT), Number(LONG)]}
          zoom={ZOOM}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          scrollWheelZoom={false}
          dragging={true}
          maxBounds={latLngBounds}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="./tiles/{z}/{x}/{y}.png"
          />
          <Marker position={[LAT, LONG]} icon={greyIcon} key="base">
            <Popup>Home</Popup>
          </Marker>
          <CourierMarkers
            highlightedCourierId={highlightedCourierId}
            highlightCourier={highlightCourier}
            isPlaying={isPlaying}
          />
          {highlightedCourier && (
            <ScheduleRoute
              jobs={jobs}
              courier={highlightedCourier}
              highlightedScheduledJob={highlightedScheduledJob}
            />
          )}
          <JobMarkers
            highlightCourier={highlightCourier}
            highlightedJobId={highlightedJobId}
            assignJob={assignJob}
            highlightedCourierId={highlightedCourierId}
            isPlaying={isPlaying}
          />
        </MapContainer>
      )}
    </div>
  );
}

export default Map;
