import { Fragment } from "react";
import * as L from "leaflet";

import { Marker, Popup } from "react-leaflet";

import styles from "./JobMarker.module.css";

import {
  UNASSIGNED,
  L_MARKER_SHADOW_URL,
  L_ICON_SIZE_SMALL,
  L_ICON_ANCHOR_SMALL,
  L_POPUP_ANCHOR_SMALL,
  L_SHADOW_SIZE_SMALL,
  L_ICON_SIZE_LARGE,
  L_ICON_ANCHOR_LARGE,
  L_POPUP_ANCHOR_LARGE,
  L_SHADOW_SIZE_LARGE,
} from "../data/constants";

const goldIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-gold.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_SMALL,
  iconAnchor: L_ICON_ANCHOR_SMALL,
  popupAnchor: L_POPUP_ANCHOR_SMALL,
  shadowSize: L_SHADOW_SIZE_SMALL,
});

const goldBigIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-gold.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_LARGE,
  iconAnchor: L_ICON_ANCHOR_LARGE,
  popupAnchor: L_POPUP_ANCHOR_LARGE,
  shadowSize: L_SHADOW_SIZE_LARGE,
});

const orangeIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-orange.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_SMALL,
  iconAnchor: L_ICON_ANCHOR_SMALL,
  popupAnchor: L_POPUP_ANCHOR_SMALL,
  shadowSize: L_SHADOW_SIZE_SMALL,
});

const orangeBigIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-orange.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_LARGE,
  iconAnchor: L_ICON_ANCHOR_LARGE,
  popupAnchor: L_POPUP_ANCHOR_LARGE,
  shadowSize: L_SHADOW_SIZE_LARGE,
});

function JobMarker({
  highlightedJobId,
  job,
  highlightedCourierId,
  assignJob,
  isPlaying
}: any) {
  const assign = (): any => {
    assignJob(job.id, highlightedCourierId);
  };

  return (
    <Fragment>
      <Marker
        position={[job.from.coordinates[1], job.from.coordinates[0]]}
        icon={highlightedJobId === job.id ? goldBigIcon : goldIcon}
        key={`${job.id}from`}
      >
        <Popup>
          {job.id} pickup{" "}
          {job.status === UNASSIGNED && highlightedCourierId && isPlaying && (
            <a onClick={assign} className={styles.assign}>Assign</a>
          )}
        </Popup>
      </Marker>
      <Marker
        position={[job.to.coordinates[1], job.to.coordinates[0]]}
        icon={highlightedJobId === job.id ? orangeBigIcon : orangeIcon}
        key={`${job.id}to`}
      >
        <Popup>
          {job.id} dropoff{" "}
          {job.status === UNASSIGNED && highlightedCourierId && isPlaying && (
            <a onClick={assign} className={styles.assign}>Assign</a>
          )}
        </Popup>
      </Marker>
    </Fragment>
  );
}

export default JobMarker;
