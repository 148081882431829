import { useContext } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import CourierRow from "./rows/CourierRow";

import styles from "./Couriers.module.css";

import { GlobalContext } from "../../context/reducers/globalReducer";

import { UNASSIGNED } from "../../data/constants";

function Couriers({ highlightCourier, highlightedCourierId, status }: any) {
  const { state } = useContext(GlobalContext);
  let { couriers } = state;

  if (status) {
    if (status === UNASSIGNED) {
      couriers = couriers.filter(
        (courier: Courier) => !courier.directions.length
      );
    } else if (status === "assigned") {
      couriers = couriers.filter(
        (courier: Courier) => courier.directions.length
      );
    }
  }

  const displayCouriers = couriers.filter((courier: Courier) => {
    return courier.added;
  });

  return (
    <div className={styles.couriers}>
      <Table>
        <TableBody>
          {displayCouriers.map((courier: Courier) => (
            <CourierRow
              key={courier.id}
              courier={courier}
              highlightCourier={highlightCourier}
              highlightedCourierId={highlightedCourierId}
              status={status}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default Couriers;
