import { useReducer, useEffect } from "react";

import Layout from "./components/Layout";
import { GlobalContext, globalReducer } from "./context/reducers/globalReducer";
import { initialState } from "./context/initialState";

import "./App.css";
import "fontsource-roboto";

const App = () => {
  const [state, dispatchToGlobal] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatchToGlobal }}>
      <Layout />
    </GlobalContext.Provider>
  );
};

export default App;
