import { useContext } from "react";

import { Button } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { GlobalContext } from "../../../context/reducers/globalReducer";

import { PLAYING } from "../../../data/constants";

import styles from "./CouriersPageRow.module.css";

function CourierPageRow({
  courier,
  highlightCourier,
  addCourier,
  highlightedCourier,
}: any) {
  const { state, } = useContext(GlobalContext);

  const highlight = (): any => {
    highlightCourier(courier.id);
  };

  const add = (): any => {
    addCourier(courier.id);
  };

  let classNames = [];

  if (highlightedCourier && highlightedCourier.id === courier.id) {
    classNames.push(styles.courierPageRowHighlighted);
  }

  return (
    <TableRow
      key={courier.id}
      className={classNames.join(" ")}
      onMouseEnter={highlight}
    >
      <TableCell size="small">{courier.name}</TableCell>
      <TableCell size="small">
        <Button onClick={add} size="small" disabled={courier.added || state.gameState !== PLAYING}>
          Add
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default CourierPageRow;
