import { Typography } from "@material-ui/core";

import styles from "./panels/Schedule.module.css";

import { PICK_UP } from "../data/constants";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  scheduleFont: {
    [theme.breakpoints.up(992)]: {
      'font-size': '1rem'
    },
    [theme.breakpoints.down(991)]: {
      'font-size': '0.8rem'
    }
  },
}));

function ScheduleDraggableBox({ provided, item, highlightScheduledJob }: any) {
  const highlight = () => {
    highlightScheduledJob(item.jobId, item.pickUpOrDropOff);
  };

  const classes = useStyles();

  return (
    <div
      onMouseEnter={highlight}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={item.pickUpOrDropOff === PICK_UP ? styles.scheduleItemStylePickUp : styles.scheduleItemStyleDropOff }
    >
      <Typography className={classes.scheduleFont}>{item.content}</Typography>
    </div>
  );
}

export default ScheduleDraggableBox;
