import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { LARGE_CUSTOMER_JOB_NO_LIMIT, MEDIUM_CUSTOMER_JOB_NO_LIMIT } from "../../data/constants";

import styles from "./CustomerDetail.module.css";

const useStyles = makeStyles({
  table: {
    minWidth: "65vw",
  },
});

function Customer({ highlightedCustomer }: any) {
  const classes = useStyles();

  const timeLimitedDeliveries = (highlightedCustomer.jobRules.find((jr: any) => jr.timeLimit));

  let customerSize = "Small";

  if (Number(highlightedCustomer.jobNoLimit) >= LARGE_CUSTOMER_JOB_NO_LIMIT) {
    customerSize = "Large";
  } else if (Number(highlightedCustomer.jobNoLimit) >= MEDIUM_CUSTOMER_JOB_NO_LIMIT) {
    customerSize = "Medium";
  }

  return (
    <div className={styles.customer}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={styles.customerDetailLeftColumn}>Name</TableCell>
            <TableCell>{highlightedCustomer.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Location</TableCell>
            <TableCell>
              {highlightedCustomer.street},&nbsp;
              {highlightedCustomer.shortPostcode}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Size</TableCell>
            <TableCell>{customerSize}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>{highlightedCustomer.description}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Time limited deliveries?</TableCell>
            <TableCell>{timeLimitedDeliveries ? ("Yes") : ("No")}</TableCell>
          </TableRow>
          {timeLimitedDeliveries && <TableRow>
            <TableCell>Lateness fine</TableCell>
            <TableCell>{highlightedCustomer.fineRate * 100}%</TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </div>
  );
}

export default Customer;
