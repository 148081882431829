export const actionTypes = {
  ASSIGN_JOB: "ASSIGN_JOB",
  UNASSIGN_JOB: "UNASSIGN_JOB",
  SET_DIRECTIONS: "SET_DIRECTIONS",
  POPULATE_GEOJSON: "POPULATE_GEOJSON",
  TICK: "TICK",
  REORDER_DIRECTIONS: "REORDER_DIRECTIONS",
  GEO_JSON_REQUESTED: "GEO_JSON_REQUESTED",
  ADD_CUSTOMER: "ADD_CUSTOMER",
  ADD_COURIER: "ADD_COURIER",
  START: "START",
  PAUSE: "PAUSE",
  UNPAUSE: "UNPAUSE",
  END_GAME: "END GAME",
  TOGGLE_MUTE: "TOGGLE MUTE",
  REMOVE_FAILED_JOB: "REMOVE_FAILED_JOB"
};
