import { makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { BIKE } from "../../data/constants";

import styles from "./CourierDetail.module.css";

const useStyles = makeStyles({
  table: {
    minWidth: "65vw",
  },
});

function CourierPage({ highlightedCourier }: any) {
  const classes = useStyles();

  let speed = 'Average';

  if (highlightedCourier.type === BIKE) {
    if (highlightedCourier.speed < 13.5) {
      speed = 'Slow';
    } else if (highlightedCourier.speed > 14.5) {
      speed = 'Fast'
    }
  } else {
    if (highlightedCourier.speed < 4.6) {
      speed = 'Slow';
    } else if (highlightedCourier.speed > 5.3) {
      speed = 'Fast'
    }
  }

  let wageStr = "None";

  if (highlightedCourier.wages) {
    wageStr = "£" + (highlightedCourier.wages / 100).toFixed(2)
  }

  return (
    <div className={styles.courierPage}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={styles.courierDetailLeftColumn}>Name</TableCell>
            <TableCell>{highlightedCourier.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Vehicle</TableCell>
            <TableCell>
              {highlightedCourier.type}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max volume</TableCell>
            <TableCell>
              {Number(highlightedCourier.volume).toLocaleString()} litres
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max weight</TableCell>
            <TableCell>
              {Number(highlightedCourier.mass).toLocaleString()} kg
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Speed</TableCell>
            <TableCell>
              {speed}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Hourly retainer</TableCell>
            <TableCell>
              {wageStr}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

export default CourierPage;
