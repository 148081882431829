import {
  BIKE,
  BIKE_VOLUME,
  BIKE_MASS,
  BIKE_LARGE_TOP,
  BIKE_LARGE_TOP_VOLUME,
  BIKE_LARGE_TOP_MASS,
  SMALL_VAN,
  SMALL_VAN_VOLUME,
  SMALL_VAN_MASS,
  MEDIUM_VAN,
  MEDIUM_VAN_VOLUME,
  MEDIUM_VAN_MASS,
  LARGE_VAN,
  LARGE_VAN_VOLUME,
  LARGE_VAN_MASS,
  LAT,
  LONG,
  UNASSIGNED,
  NOT_STARTED,
  MIN_MULTIPLIER
} from "../data/constants";

import {
  BELT,
  SUIT,
  CLOTHES_RACK,
  PERFUME,
  SHOES,
  ENVELOPE,
  LEVER_ARCH_FILE,
  FILING_BOX,
  TEN_FILING_BOXES,
  TWENTY_FILING_BOXES,
  STAGE_WEIGHT,
  STAGE_CURTAINS,
  MUSIC_SCORE,
  OPERA_COSTUME,
  OPERA_SHOES,
  OPERA_GLASSES,
  OPERA_MASK,
  CONDUCTOR_BATON,
  SUPERTITLE_SCREEN,
  OPERA_SCENERY,
  SPOTLIGHT,
  INVOICE,
  CONTRACT,
  INTEL_4004,
  TELSTAR_303,
  BT_MERLIN_TONTO,
  ANALYTIC_ENGINE,
  GNK_POWER_DROID,
  ORAC,
  MACHINE_PERSON,
  T1000000,
  AGC,
  BRAND_ENDORSEMENT_CONTRACT,
  LEGAL_FORMS,
  PHONE,
  SPACE_PROBE,
  CALCULATOR,
  CEREAL,
  RELIGIOUS_BISCUITS,
  MONKEY_BITES,
  FILM_REEL,
  ELEPHANT_STATUE,
  STUFFED_ANIMAL,
  SUBMARINE,
  ORANGES,
  FLAMINGOES,
  PRUSSIAN_WAISTCOAT,
  DIRECTORS_CHAIR,
  SPACESHIP,
  FILM_CAMERA,
  BALLOONS,
  FLOWERS,
  BOTTLE_OF_WINE,
  CASE_OF_WINE,
  GREEN_PLANK,
  SPIDERS,
  CRICKETS,
  MICE,
  PURPLE_PENS,
  HAMBURGERS,
  SPACE_INVADERS,
  KEYS,
  SECTION_21,
  LARGE_HEAVY_BROWN_ENVELOPE,
  CASE_OF_CHAMPAGNE,
  HAMPER,
  FABERGE_EGG,
  COLA,
  PLACARD,
  P45,
  CHEQUE,
  PITCH_DECK,
  HAT,
  COAT,
  GLOVES,
  CIGARS,
  SMALL_BOX,
  MEDIUM_BOX,
  LARGE_BOX,
  TV,
  SAUSAGE,
  SELLOTAPE,
  FRIDGE,
  NEWSPAPER,
  OWL,
  SANDWICH,
  PIE,
  WALKING_STICK,
  EYELINER,
  BOOTS
} from "../data/items";

import { items } from "../data/items";

const pr1Location: RDLocation = {
  "id": "pr-1",
  "coordinates": [-0.114988, 51.515638],
  "street": "Lincoln's Inn Fields",
  "shortPostcode": "WC2"
}

const pr2Location: RDLocation = {
  "id": "pr-2",
  "coordinates": [-0.137647, 51.520178],
  "street": "Cleveland St",
  "shortPostcode": "W1"
}

// @todo - move to own file, they're not part of the initial state as they don't change
export const locations: RDLocation[] = [
  {
    "id": "home",
    "coordinates": [-0.12973, 51.51611],
    "street": "New Oxford Street",
    "shortPostcode": "WC1"
  },
  {
    "id": "a-company",
    "coordinates": [-0.13726, 51.51264],
    "street": "Beak Street",
    "shortPostcode": "W1"  
  },
  {
    "id": "b-company",
    "coordinates": [-0.10919, 51.512778],
    "street": "Temple",
    "shortPostcode": "EC4"  
  },
  {
    "id": "c-company",
    "coordinates": [-0.149132, 51.517067],
    "street": "Wellbeck Street",
    "shortPostcode": "W1"  
  },
  {
    "id": "d-company",
    "coordinates": [-0.1694, 51.5250],
    "street": "Lisson Grove",
    "shortPostcode": "NW8"  
  },
  {
    "id": "e-company",
    "coordinates": [-0.123083, 51.512314],
    "street": "Henrietta Street",
    "shortPostcode": "WC2"
  },
  {
    "id": "f-company",
    "coordinates": [-0.0823, 51.5130],
    "street": "Lime Street",
    "shortPostcode": "EC3"  
  },
  {
    "id": "g-company",
    "coordinates": [-0.136416, 51.511791],
    "street": "Golden Square",
    "shortPostcode": "W1"  
  },
  {
    "id": "h-company",
    "coordinates": [-0.067993, 51.521418],
    "street": "Hunton Street",
    "shortPostcode": "E1"
  },
  {
    "id": "i-company",
    "coordinates": [-0.0169, 51.5026],
    "street": "Upper Bank Street",
    "shortPostcode": "E14"  
  },
  {
    "id": "j-company",
    "coordinates": [-0.134518, 51.512097],
    "street": "Brewer Street",
    "shortPostcode": "W1"  
  },
  {
    "id": "k-company",
    "coordinates": [-0.111049, 51.512419],
    "street": "Temple",
    "shortPostcode": "EC4"  
  },
  {
    "id": "l-company",
    "coordinates": [-0.1280, 51.4963],
    "street": "Tufton Street",
    "shortPostcode": "SW1"
  },
  {
    "id": "m-company",
    "coordinates": [-0.1082, 51.5075],
    "street": "Stamford Street",
    "shortPostcode": "SE1"  
  },
  {
    "id": "n-company",
    "coordinates": [-0.1147, 51.4627],
    "street": "Atlantic Rd",
    "shortPostcode": "SW9"  
  },
  {
    "id": "o-company",
    "coordinates": [-0.1891, 51.5061],
    "street": "Kensington Palace Gardens",
    "shortPostcode": "W8"  
  },
  {
    "id": "p-company",
    "coordinates": [-0.1564, 51.5406],
    "street": "Chalcot Crescent",
    "shortPostcode": "NW1"
  },
  {
    "id": "q-company",
    "coordinates": [-0.1295, 51.4926],
    "street": "Erasmus Street",
    "shortPostcode": "SW1"
  },
  {
    "id": "r-company",
    "coordinates": [-0.1090, 51.5133],
    "street": "King's Bench Walk",
    "shortPostcode": "EC4"
  },
  {
    "id": "s-company",
    "coordinates": [-0.1348, 51.5151],
    "street": "Sheraton Street",
    "shortPostcode": "W1"  
  },
  {
    "id": "t-company",
    "coordinates": [-0.1100, 51.5143],
    "street": "Fleet Street",
    "shortPostcode": "EC4"  
  },
  {
    "id": "u-company",
    "coordinates": [-0.1237, 51.4880],
    "street": "Albert Embankment",
    "shortPostcode": "SE1"  
  },
  {
    "id": "v-company",
    "coordinates": [-0.2246, 51.4923],
    "street": "Queen Caroline Street",
    "shortPostcode": "SW6"  
  },
  {
    "id": "w-company",
    "coordinates": [-0.136186, 51.512345],
    "street": "Great Pulteney St",
    "shortPostcode": "W1"  
  },
  {
    "id": "x-company",
    "coordinates": [-0.123297, 51.53238],
    "street": "Euston Road",
    "shortPostcode": "N1"  
  },
  {
    "id": "y-company",
    "coordinates": [-0.286859, 51.616590],
    "street": "Yarnton Way",
    "shortPostcode": "SE2"  
  },
  {
    "id": "z-company",
    "coordinates": [-0.274062, 51.531392],
    "street": "Abbey Road",
    "shortPostcode": "NW10"  
  },
  {
    "id": "tech",
    "coordinates": [-0.1251, 51.5329],
    "street": "Pancras Square",
    "shortPostcode": "N1"
  },
  pr1Location,
  pr2Location,
  {
    "id": "pr-3",
    "coordinates": [-0.085762, 51.522795],
    "street": "Tabernacle Street",
    "shortPostcode": "EC2"
  },
  {
    "id": "pr-4",
    "coordinates": [-0.1153, 51.6124],
    "street": "Bowes Road",
    "shortPostcode": "N13"
  },
  {
    "id": "pr-5",
    "coordinates": [-0.001128, 51.629811],
    "street": "Woodland Road",
    "shortPostcode": "E4"
  },
  {
    "id": "pr-6",
    "coordinates": [-0.1659, 51.5465],
    "street": "Lambolle Road",
    "shortPostcode": "NW3"
  },
  {
    "id": "pr-7",
    "coordinates": [-0.0671, 51.4419],
    "street": "Cox's Walk",
    "shortPostcode": "SE22"
  },
  {
    "id": "pr-8",
    "coordinates": [-0.0571, 51.5315],
    "street": "Poulett Road",
    "shortPostcode": "E6"
  },
  {
    "id": "pr-9",
    "coordinates": [-0.0308, 51.4914],
    "street": "Atlas Gardens",
    "shortPostcode": "SE7"
  },
  {
    "id": "pr-10",
    "coordinates": [-0.0284, 51.5814],
    "street": "Track Street",
    "shortPostcode": "E17"
  },
  {
    "id": "pr-11",
    "coordinates": [-0.1800, 51.4882],
    "street": "Thistle Grove",
    "shortPostcode": "SW10"
  },
  {
    "id": "pr-12",
    "coordinates": [-0.1120, 51.5385],
    "street": "Richmond Avenue",
    "shortPostcode": "N1"
  },
  {
    "id": "pr-13",
    "coordinates": [-0.0694, 51.4721],
    "street": "Hanover Park",
    "shortPostcode": "SE15"
  },
  {
    "id": "pr-14",
    "coordinates": [-0.1487, 51.6172],
    "street": "Parkhurst Road",
    "shortPostcode": "N11"
  },
  {
    "id": "pr-15",
    "coordinates": [-0.1467, 51.4894],
    "street": "Sutherland Street",
    "shortPostcode": "SW1"
  },
  {
    "id": "pr-16",
    "coordinates": [-0.1801, 51.5300],
    "street": "Hamilton Terrace",
    "shortPostcode": "NW8"
  },
  {
    "id": "pr-17",
    "coordinates": [-0.0681, 51.5107],
    "street": "Cable Street",
    "shortPostcode": "E1"
  },
  {
    "id": "pr-18",
    "coordinates": [-0.1228, 51.5388],
    "street": "York Way",
    "shortPostcode": "N1"
  },
  {
    "id": "pr-19",
    "coordinates": [-0.1705, 51.5336],
    "street": "St John's Wood High Street",
    "shortPostcode": "NW8"
  },
  {
    "id": "pr-20",
    "coordinates": [-0.1356, 51.5640],
    "street": "Bredgar Road",
    "shortPostcode": "N19"
  },
  {
    "id": "pr-21",
    "coordinates": [-0.0228, 51.584],
    "street": "Eagle Lane",
    "shortPostcode": "E11"
  },
  {
    "id": "pr-22",
    "coordinates": [-0.3082, 51.5232],
    "street": "Woodfield Road",
    "shortPostcode": "W5"
  },
  {
    "id": "pr-23",
    "coordinates": [-0.2054, 51.4078],
    "street": "Mostyn Road",
    "shortPostcode": "SW19"
  },
  {
    "id": "pr-24",
    "coordinates": [-0.1643, 51.5558],
    "street": "Parliament Hill",
    "shortPostcode": "NW3"
  },
  {
    "id": "pr-25",
    "coordinates": [-0.1181, 51.4827],
    "street": "Bostall Hill",
    "shortPostcode": "SE2"
  },
  {
    "id": "pr-26",
    "coordinates": [-0.0285, 51.5263],
    "street": "British Street",
    "shortPostcode": "E3"
  },
  {
    "id": "pr-27",
    "coordinates": [-0.0129, 51.4992],
    "street": "Skylines Village",
    "shortPostcode": "E14"
  },
  {
    "id": "pr-28",
    "coordinates": [-0.0672, 51.5501],
    "street": "Blakeney Close",
    "shortPostcode": "E8"
  },
  {
    "id": "pr-29",
    "coordinates": [-0.0763, 51.4101],
    "street": "Cantley Gardens",
    "shortPostcode": "SE19"
  },
  {
    "id": "pr-30",
    "coordinates": [-0.0349, 51.5715],
    "street": "Overton Drive",
    "shortPostcode": "E11"
  },
  {
    "id": "pr-31",
    "coordinates": [-0.1917, 51.5320],
    "street": "Carlton Vale",
    "shortPostcode": "NW6"
  },
  {
    "id": "pr-32",
    "coordinates": [-0.1316, 51.6418],
    "street": "Avenue Road",
    "shortPostcode": "N14"
  },
  {
    "id": "pr-33",
    "coordinates": [-0.1497, 51.4940],
    "street": "Ebury Street",
    "shortPostcode": "SW1W"
  },
  {
    "id": "pr-34",
    "coordinates": [-0.0188, 51.4248],
    "street": "Oslac Road",
    "shortPostcode": "SE6"
  },
  {
    "id": "pr-35",
    "coordinates": [-0.1299, 51.4881],
    "street": "Grosvenor Road",
    "shortPostcode": "SW1"
  },
  {
    "id": "ten-1",
    "coordinates": [-0.102000, 51.520798],
    "street": "St John's Lane",
    "shortPostcode": "EC1"
  },
  {
    "id": "ten-2",
    "coordinates": [-0.080543, 51.520905],
    "street": "Appold Street",
    "shortPostcode": "EC2"
  },
  {
    "id": "ten-3",
    "coordinates": [-0.076573, 51.511776],
    "street": "Crosswall",
    "shortPostcode": "EC3"
  },
  {
    "id": "ten-4",
    "coordinates": [-0.089812, 51.510596],
    "street": "Suffolk Lane",
    "shortPostcode": "EC4"
  },
  {
    "id": "ten-5",
    "coordinates": [-0.138458, 51.517088],
    "street": "Margaret Street",
    "shortPostcode": "W1"
  },
  {
    "id": "ten-6",
    "coordinates": [-0.102108, 51.502606],
    "street": "Surrey Row",
    "shortPostcode": "SE1"
  },
  {
    "id": "ten-7",
    "coordinates": [-0.108971, 51.542934],
    "street": "Barnsbury Park",
    "shortPostcode": "N1"
  },
  {
    "id": "ten-8",
    "coordinates": [-0.071874, 51.520691],
    "street": "Brick Lane",
    "shortPostcode": "E1"
  },
  {
    "id": "ten-9",
    "coordinates": [-0.141986, 51.527019],
    "street": "Osnaburgh Street",
    "shortPostcode": "NW1"
  },
  {
    "id": "ten-10",
    "coordinates": [-0.171660, 51.519373],
    "street": "Harbet Road",
    "shortPostcode": "W2"
  },
  {
    "id": "ten-11",
    "coordinates": [-0.095563, 51.515243],
    "street": "Gutter Lane",
    "shortPostcode": "EC1"
  },
  {
    "id": "ten-12",
    "coordinates": [-0.093589, 51.515564],
    "street": "Gresham Street",
    "shortPostcode": "EC2"
  },
  {
    "id": "ten-13",
    "coordinates": [-0.080181, 51.515255],
    "street": "St Mary Axe",
    "shortPostcode": "EC3"
  },
  {
    "id": "ten-14",
    "coordinates": [-0.092194, 51.513433],
    "street": "Queen Street",
    "shortPostcode": "EC4"
  },
  {
    "id": "ten-15",
    "coordinates": [-0.137986, 51.513546],
    "street": "Marshall Street",
    "shortPostcode": "W1"
  },
  {
    "id": "ten-16",
    "coordinates": [-0.099082, 51.503380],
    "street": "Lavington Street",
    "shortPostcode": "SE1"
  },
  {
    "id": "ten-17",
    "coordinates": [-0.087943, 51.542988],
    "street": "Oakley Road",
    "shortPostcode": "N1"
  },
  {
    "id": "ten-18",
    "coordinates": [-0.065951, 51.519516],
    "street": "Hanbury Street",
    "shortPostcode": "E1"
  },
  {
    "id": "ten-19",
    "coordinates": [-0.130609, 51.529520],
    "street": "Church Street",
    "shortPostcode": "NW1"
  },
  {
    "id": "ten-20",
    "coordinates": [-0.194491, 51.512750],
    "street": "Dawson Place",
    "shortPostcode": "W2"
  },
  {
    "id": "ten-21",
    "coordinates": [-0.079255, 51.527260],
    "street": "Old Street",
    "shortPostcode": "EC1"
  },
  {
    "id": "ten-22",
    "coordinates": [-0.090027, 51.519130],
    "street": "Ropemaker Street",
    "shortPostcode": "EC2"
  },
  {
    "id": "ten-23",
    "coordinates": [-0.079694, 51.513417],
    "street": "Leadenhall Street",
    "shortPostcode": "EC3"
  },
  {
    "id": "ten-24",
    "coordinates": [-0.096940, 51.512906],
    "street": "Cannon Street",
    "shortPostcode": "EC4"
  },
  {
    "id": "ten-25",
    "coordinates": [-0.142063, 51.512494],
    "street": "Conduit Street",
    "shortPostcode": "W1"
  },
  {
    "id": "ten-26",
    "coordinates": [-0.085585, 51.496424],
    "street": "Rothsay Street",
    "shortPostcode": "SE1"
  },
  {
    "id": "ten-27",
    "coordinates": [-0.091204, 51.530068],
    "street": "Underwood Street",
    "shortPostcode": "N1"
  },
  {
    "id": "ten-28",
    "coordinates": [-0.070683, 51.509342],
    "street": "Cartwright Street",
    "shortPostcode": "E1"
  },
  {
    "id": "ten-29",
    "coordinates": [-0.138038, 51.540154],
    "street": "Lyme Street",
    "shortPostcode": "NW1"
  },
  {
    "id": "ten-30",
    "coordinates": [-0.178698, 51.512643],
    "street": "Lancaster Gate",
    "shortPostcode": "W2"
  },
  {
    "id": "ten-31",
    "coordinates": [-0.097194, 51.523308],
    "street": "Baltic Street West",
    "shortPostcode": "EC1"
  },
  {
    "id": "ten-32",
    "coordinates": [-0.085177, 51.520477],
    "street": "Sun Street",
    "shortPostcode": "EC2"
  },
  {
    "id": "ten-33",
    "coordinates": [-0.077109, 51.512092],
    "street": "Crutched Friars",
    "shortPostcode": "EC3"
  },
  {
    "id": "ten-34",
    "coordinates": [-0.099061, 51.512337],
    "street": "Godliman Street",
    "shortPostcode": "EC4"
  },
  {
    "id": "ten-35",
    "coordinates": [-0.144638, 51.512548],
    "street": "New Bond Street",
    "shortPostcode": "W1"
  },
  {
    "id": "ten-36",
    "coordinates": [-0.076079, 51.503216],
    "street": "Brewery Street",
    "shortPostcode": "SE1"
  },
  {
    "id": "ten-37",
    "coordinates": [-0.100045, 51.540105],
    "street": "Hawes Street",
    "shortPostcode": "N1"
  },
  {
    "id": "ten-38",
    "coordinates": [-0.073336, 51.510084],
    "street": "Shorter Street",
    "shortPostcode": "E1"
  },
  {
    "id": "ten-39",
    "coordinates": [-0.152457, 51.539994],
    "street": "Chalcot Road",
    "shortPostcode": "NW1"
  },
  {
    "id": "ten-40",
    "coordinates": [-0.189856, 51.517557],
    "street": "Hatherley Grove",
    "shortPostcode": "W2"
  },
  {
    "id": "ten-41",
    "coordinates": [-0.095134, 51.52624],
    "street": "Norman Street",
    "shortPostcode": "EC1"
  },
  {
    "id": "ten-42",
    "coordinates": [-0.084319, 51.518822],
    "street": "Broadgate",
    "shortPostcode": "EC2"
  },
  {
    "id": "ten-43",
    "coordinates": [-0.075975, 51.512214],
    "street": "Minories",
    "shortPostcode": "EC3"
  },
  {
    "id": "ten-44",
    "coordinates": [-0.103373, 51.515884],
    "street": "Fleet Place",
    "shortPostcode": "EC4"
  },
  {
    "id": "ten-45",
    "coordinates": [-0.148415, 51.521948],
    "street": "Harley Street",
    "shortPostcode": "W1"
  },
  {
    "id": "ten-46",
    "coordinates": [-0.092645, 51.494828],
    "street": "Harper Road",
    "shortPostcode": "SE1"
  },
  {
    "id": "ten-47",
    "coordinates": [-0.111031, 51.532844],
    "street": "Penton Street",
    "shortPostcode": "N1"
  },
  {
    "id": "ten-48",
    "coordinates": [-0.075221, 51.515724],
    "street": "Middlesex Street",
    "shortPostcode": "E1"
  },
  {
    "id": "ten-49",
    "coordinates": [-0.1268, 51.5016],
    "street": "Parliament Street",
    "shortPostcode": "SW1"
  },
  {
    "id": "ten-50",
    "coordinates": [-0.171317, 51.513658],
    "street": "Tyburnia",
    "shortPostcode": "W2"
  },
  {
    "id": "ten-51",
    "coordinates": [-0.127721, 51.503368],
    "street": "Downing Street",
    "shortPostcode": "SW1"
  },
  {
    "id": "fashion-1",
    "coordinates": [-0.155791, 51.501209],
    "street": "Wilton Place",
    "shortPostcode": "SW1"
  },
  {
    "id": "fashion-2",
    "coordinates": [-0.186170, 51.496138],
    "street":  "Cromwell Road",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-3",
    "coordinates": [-0.179475, 51.488857],
    "street": "Evelyn Gardens",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-4",
    "coordinates": [-0.172180, 51.498529],
    "street": "Prince's Gardens",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-5",
    "coordinates": [-0.200784, 51.497784],
    "street": "Earl's Terrace",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-6",
    "coordinates": [-0.193317, 51.496021],
    "street": "Lexham Gardens",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-7",
    "coordinates": [-0.2368, 51.5425],
    "street": "Robson Avenue",
    "shortPostcode": "NW10"
  },
  {
    "id": "fashion-8",
    "coordinates": [-0.1458, 51.4188],
    "street": "Fallsbrook Road",
    "shortPostcode": "SW16"
  },
  {
    "id": "fashion-9",
    "coordinates": [-0.1590, 51.5504],
    "street": "Tasker Road",
    "shortPostcode": "NW3"
  },
  {
    "id": "fashion-10",
    "coordinates": [-0.135020, 51.506470],
    "street": "St James's Square",
    "shortPostcode": "SW1Y"
  },
  {
    "id": "fashion-11",
    "coordinates": [-0.182222, 51.498797],
    "street": "Queen's Gate Terrace",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-12",
    "coordinates": [-0.175656, 51.490647],
    "street": "Foulis Terrace",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-13",
    "coordinates": [-0.196278, 51.506279],
    "street": "Peel's Passage",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-14",
    "coordinates": [-0.197136, 51.502005],
    "street": "Argyll Road",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-15",
    "coordinates": [-0.190871, 51.498104],
    "street": "St Margaret's Lane",
    "shortPostcode": ""
  },
  {
    "id": "fashion-16",
    "coordinates": [-0.0697, 51.4762],
    "street": "Bonar Road",
    "shortPostcode": "SE15"
  },
  {
    "id": "fashion-17",
    "coordinates": [-0.1901, 51.5040],
    "street": "York Passage",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-18",
    "coordinates": [0.0777, 51.4362],
    "street": "Dulverton Road",
    "shortPostcode": "SE9"
  },
  {
    "id": "fashion-19",
    "coordinates": [-0.1800, 51.6207],
    "street": "Squirrels Close",
    "shortPostcode": "N12"
  },
  {
    "id": "fashion-20",
    "coordinates": [-0.129527, 51.489959],
    "street": "Ponsonby Place",
    "shortPostcode": "SW1"
  },
  {
    "id": "fashion-21",
    "coordinates": [-0.180720, 51.496713],
    "street": "Queen's Gate Gardens",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-22",
    "coordinates": [-0.173467, 51.492064],
    "street":  "Onslow Square",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-23",
    "coordinates": [-0.194089, 51.503501],
    "street": "Gordon Place",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-24",
    "coordinates": [-0.200141, 51.502593],
    "street": "Duchess of Bedford's Walk",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-25",
    "coordinates": [-0.184476, 51.499574],
    "street": "De Vere Gardens",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-26",
    "coordinates": [-0.2230, 51.6188],
    "street": "St Vincent's Lane",
    "shortPostcode": "NW7"
  },
  {
    "id": "fashion-27",
    "coordinates": [-0.0111, 51.5326],
    "street": "Marshgate Lane",
    "shortPostcode": "E15"
  },
  {
    "id": "fashion-28",
    "coordinates": [-0.1357, 51.5951],
    "street": "Grove Avenue",
    "shortPostcode": "N10"
  },
  {
    "id": "fashion-29",
    "coordinates": [-0.2144, 51.5032],
    "street": "Holland Road",
    "shortPostcode": "W14"
  },
  {
    "id": "fashion-30",
    "coordinates": [-0.142058, 51.489104],
    "street": "Cambridge Street",
    "shortPostcode": "SW1"
  },
  {
    "id": "fashion-31",
    "coordinates": [-0.172137, 51.499999],
    "street": "Prince's Gardens",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-32",
    "coordinates": [-0.165656, 51.500480],
    "street": "Trevor Place",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-33",
    "coordinates": [-0.199583, 51.508069],
    "street": "Notting Hill Gate",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-34",
    "coordinates": [-0.197823, 51.497730],
    "street": "Earl's Court Road",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-35",
    "coordinates": [-0.197909, 51.496822],
    "street": "Pembroke Square",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-36",
    "coordinates": [-0.1346, 51.5395],
    "street": "St. Pancras Way",
    "shortPostcode": "NW1"
  },
  {
    "id": "fashion-37",
    "coordinates": [-0.2078, 51.4440],
    "street": "Wimbledon Park Road",
    "shortPostcode": "SW19"
  },
  {
    "id": "fashion-38",
    "coordinates": [-0.2221, 51.4761],
    "street": "Stevenage Road",
    "shortPostcode": "SW6"
  },
  {
    "id": "fashion-39",
    "coordinates": [-0.2043, 51.4569],
    "street": "Upper Richmond Road",
    "shortPostcode": "SW15"
  },
  {
    "id": "fashion-40",
    "coordinates": [-0.161198, 51.496265],
    "street": "Cadogan Square",
    "shortPostcode": "SW1"
  },
  {
    "id": "fashion-41",
    "coordinates": [-0.171707 ,51.493827],
    "street": "Thurloe Square",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-42",
    "coordinates": [-0.177716, 51.494629],
    "street": "Queensberry Pl",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-43",
    "coordinates": [-0.180763, 51.498102],
    "street": "Gore Street",
    "shortPostcode": "SW7"
  },
  {
    "id": "fashion-44",
    "coordinates": [-0.200098, 51.502646],
    "street": "Duchess of Bedford's Walk",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-45",
    "coordinates": [-0.202329, 51.506867],
    "street": "Campden Hill Square",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-46",
    "coordinates": [-0.197609, 51.501043],
    "street": "Essex Villas",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-47",
    "coordinates": [-0.188124, 51.500723],
    "street": "Kensington Court",
    "shortPostcode": "W8"
  },
  {
    "id": "fashion-48",
    "coordinates": [-0.2358, 51.4555],
    "street": "Crestway",
    "shortPostcode": "SW15"
  },
  {
    "id": "fashion-49",
    "coordinates": [-0.0213, 51.5393],
    "street": "Stour Road",
    "shortPostcode": "E3"
  },
  {
    "id": "fashion-50",
    "coordinates": [-0.152015, 51.500913],
    "street": "Halkin Street",
    "shortPostcode": "SW1"
  },
  {
    "id": "greater-1",
    "coordinates": [-0.0234, 51.4737],
    "street": "Brookmill Road",
    "shortPostcode": "SE8"
  },
  {
    "id": "greater-2",
    "coordinates": [0.0111, 51.4985],
    "street": "East Ferry Road",
    "shortPostcode": "E14"
  },
  {
    "id": "greater-3",
    "coordinates": [-0.2192, 51.4109],
    "street": "Kingston Road",
    "shortPostcode": "SW20"
  },
  {
    "id": "greater-4",
    "coordinates": [-0.1971, 51.4607],
    "street": "Osiers Road",
    "shortPostcode": "SW18"
  },
  {
    "id": "greater-5",
    "coordinates": [-0.0626, 51.4971],
    "street": "Tranton Road",
    "shortPostcode": "SE16"
  },
  {
    "id": "greater-6",
    "coordinates": [-0.0933, 51.5745],
    "street": "Vale Grove",
    "shortPostcode": "N4"
  },
  {
    "id": "greater-7",
    "coordinates": [-0.1311, 51.5091],
    "street": "Orange Street",
    "shortPostcode": "WC2"
  },
  {
    "id": "greater-8",
    "coordinates": [-0.1977, 51.4464],
    "street": "Merton Road",
    "shortPostcode": "SW18"
  },
  {
    "id": "greater-9",
    "coordinates": [0.0311, 51.5632],
    "street": "Aldersbrook Road",
    "shortPostcode": "E11"
  },
  {
    "id": "greater-10",
    "coordinates": [-0.0252, 51.4768],
    "street": "Hales Street",
    "shortPostcode": "SE8"
  },
  {
    "id": "greater-11",
    "coordinates": [-0.0164, 51.5386],
    "street": "Marathon Ramp",
    "shortPostcode": "E20"
  },
  {
    "id": "greater-12",
    "coordinates": [-0.0188, 51.5285],
    "street": "Bor Road",
    "shortPostcode": "E3"
  },
  {
    "id": "greater-13",
    "coordinates": [-0.2041, 51.4609],
    "street": "Fawe Park Road",
    "shortPostcode": "SW15"
  },
  {
    "id": "greater-14",
    "coordinates": [-0.2422, 51.5776],
    "street": "Moorhen Drive",
    "shortPostcode": "NW9"
  },
  {
    "id": "greater-15",
    "coordinates": [0.0062, 51.5138],
    "street": "Lookout Lane",
    "shortPostcode": "E14"
  },
  {
    "id": "greater-16",
    "coordinates": [-0.0665, 51.4549],
    "street": "Friern Road",
    "shortPostcode": "SE22"
  },
  {
    "id": "greater-17",
    "coordinates": [-0.1897, 51.5016],
    "street": "Young Street",
    "shortPostcode": "W8"
  },
  {
    "id": "greater-18",
    "coordinates": [-0.132467, 51.598041],
    "street": "Vallance Road",
    "shortPostcode": "N22"
  },
  {
    "id": "greater-19",
    "coordinates": [-0.2022, 51.4828],
    "street": "Haldane Road",
    "shortPostcode": "SW6"
  },
  {
    "id": "greater-20",
    "coordinates": [-0.1695, 51.4987],
    "street": "Ennismore Gardens",
    "shortPostcode": "SW7"
  },
  {
    "id": "greater-21",
    "coordinates": [-0.1744, 51.5261],
    "street": "Victoria Passage",
    "shortPostcode": "NW8"
  },
  {
    "id": "greater-22",
    "coordinates": [-0.0806, 51.4622],
    "street": "Francis Road",
    "shortPostcode": "SE22"
  },
  {
    "id": "greater-23",
    "coordinates": [-0.1783, 51.5941],
    "street": "Briar Close",
    "shortPostcode": "N2"
  },
  {
    "id": "greater-24",
    "coordinates": [-0.2144, 51.5296],
    "street": "Ilbert Street",
    "shortPostcode": "W10"
  },
  {
    "id": "greater-25",
    "coordinates": [0.0133, 51.5921],
    "street": "High View Road",
    "shortPostcode": "E18"
  },
];

const INITIAL_CUSTOMERS: any = [
  {
    id: "1",
    name: "Agrajag PR",
    description: "PR firm",
    street: locations[1].street,
    shortPostcode: locations[1].shortPostcode,
    jobNoLimit: 1,
    added: true,
    addressesFrom: [
      {
        locationId: "a-company",
        odds: 4
      },
      {
        locationId: "home",
        odds: 2
      },
    ],
    addressesTo: [
      {
        locationId: "pr-1",
        odds: 1
      },
      {
        locationId: "pr-2",
        odds: 1
      },
      {
        locationId: "pr-3",
        odds: 1
      },
      {
        locationId: "pr-4",
        odds: 1
      },
      {
        locationId: "pr-5",
        odds: 1
      },
      {
        locationId: "pr-6",
        odds: 1
      },
      {
        locationId: "pr-7",
        odds: 1
      },
      {
        locationId: "pr-8",
        odds: 1
      },
      {
        locationId: "pr-9",
        odds: 1
      },
      {
        locationId: "pr-10",
        odds: 1
      },
      {
        locationId: "pr-11",
        odds: 1
      },
      {
        locationId: "pr-12",
        odds: 1
      },
      {
        locationId: "pr-13",
        odds: 1
      },
      {
        locationId: "pr-14",
        odds: 1
      },
      {
        locationId: "pr-15",
        odds: 1
      },
      {
        locationId: "pr-16",
        odds: 1
      },
      {
        locationId: "pr-17",
        odds: 1
      },
      {
        locationId: "pr-18",
        odds: 1
      },
      {
        locationId: "pr-19",
        odds: 1
      },
      {
        locationId: "pr-20",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 5 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 450 * MIN_MULTIPLIER
      }
    ],
    items: [
      {
        "itemId": PERFUME,
        "odds": "1"
      },
      {
        "itemId": SHOES,
        "odds": "1"
      },
      {
        "itemId": PHONE,
        "odds": "1"
      }
    ],
    fineRate: 0
  },
  {
    id: "2",
    name: "Bubble and Squeak LLP",
    description: "Law firm",
    street: locations[2].street,
    shortPostcode: locations[2].shortPostcode,
    jobNoLimit: 1,
    added: false,
    addressesFrom: [
      {
        locationId: "b-company",
        odds: 5
      },
      {
        locationId: "home",
        odds: 1
      },
    ],
    addressesTo: [
      {
        locationId: "ten-1",
        odds: 1
      },
      {
        locationId: "ten-2",
        odds: 1
      },
      {
        locationId: "ten-3",
        odds: 1
      },
      {
        locationId: "ten-4",
        odds: 1
      },
      {
        locationId: "ten-5",
        odds: 1
      },
      {
        locationId: "ten-6",
        odds: 1
      },
      {
        locationId: "ten-7",
        odds: 1
      },
      {
        locationId: "ten-8",
        odds: 1
      },
      {
        locationId: "ten-9",
        odds: 1
      },
      {
        locationId: "ten-10",
        odds: 1
      },
      {
        locationId: "ten-11",
        odds: 1
      },
      {
        locationId: "ten-12",
        odds: 1
      },
      {
        locationId: "ten-13",
        odds: 1
      },
      {
        locationId: "ten-14",
        odds: 1
      },
      {
        locationId: "ten-15",
        odds: 1
      },
      {
        locationId: "ten-16",
        odds: 1
      },
      {
        locationId: "ten-17",
        odds: 1
      },
      {
        locationId: "ten-18",
        odds: 1
      },
      {
        locationId: "ten-19",
        odds: 1
      },
      {
        locationId: "ten-20",
        odds: 1
      },
    ],
    jobRules: [ // Stops for lunch
      {
        id: "1",
        type: "random",
        schedule: 15 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 180 * MIN_MULTIPLIER
      },
      {
        id: "2",
        type: "random",
        schedule: 15 * MIN_MULTIPLIER,
        startTick: 240 * MIN_MULTIPLIER,
        endTick: 450 * MIN_MULTIPLIER
      }
    ],
    items: [
      {
        itemId: ENVELOPE,
        odds: 10
      },
      {
        itemId: LEVER_ARCH_FILE,
        odds: 8
      },
      {
        itemId: FILING_BOX,
        odds: 5
      },
      {
        itemId: TEN_FILING_BOXES,
        odds: 2
      },
      {
        itemId: TWENTY_FILING_BOXES,
        odds: 1
      }
    ],
    fineRate: 0
  },
  {
    id: "3",
    name: "Claptrap Fashion",
    description: "Fashion company",
    street: locations[3].street,
    shortPostcode: locations[3].shortPostcode,
    jobNoLimit: 1,
    added: false,
    addressesFrom: [
      {
        locationId: "c-company",
        odds: 3
      },
      {
        locationId: "home",
        odds: 1
      },
    ],
    addressesTo: [
      {
        locationId: "fashion-1",
        odds: 3
      },
      {
        locationId: "fashion-2",
        odds: 2
      },
      {
        locationId: "fashion-3",
        odds: 2
      },
      {
        locationId: "fashion-4",
        odds: 1
      },
      {
        locationId: "fashion-5",
        odds: 1
      },
      {
        locationId: "fashion-6",
        odds: 1
      },
      {
        locationId: "fashion-7",
        odds: 1
      },
      {
        locationId: "fashion-8",
        odds: 1
      },
      {
        locationId: "fashion-9",
        odds: 1
      },
      {
        locationId: "fashion-10",
        odds: 2
      },
      {
        locationId: "fashion-11",
        odds: 2
      },
      {
        locationId: "fashion-12",
        odds: 2
      },
      {
        locationId: "fashion-13",
        odds: 2
      },
      {
        locationId: "fashion-14",
        odds: 1
      },
      {
        locationId: "fashion-15",
        odds: 1
      },
      {
        locationId: "fashion-16",
        odds: 1
      },
      {
        locationId: "fashion-17",
        odds: 1
      },
      {
        locationId: "fashion-18",
        odds: 1
      },
      {
        locationId: "fashion-19",
        odds: 1
      },
      {
        locationId: "fashion-20",
        odds: 1
      },
    ],
    jobRules: [ // Stops for lunch
      {
        id: "1",
        type: "scheduled",
        schedule: 5 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 240 * MIN_MULTIPLIER
      },
      {
        id: "2",
        type: "scheduled",
        schedule: 5 * MIN_MULTIPLIER,
        startTick: 240 * MIN_MULTIPLIER,
        endTick: 300 * MIN_MULTIPLIER
      }
    ],
    items: [
      {
        itemId: BELT,
        odds: 1
      },
      {
        itemId: SUIT,
        odds: 1
      },
      {
        itemId: CLOTHES_RACK,
        odds: 1
      }
    ],
    fineRate: .2
  },
  {
    id: "4",
    name: "Doolittle Estate Agents",
    description: "Estate Agents",
    street: locations[4].street,
    shortPostcode: locations[4].shortPostcode,
    jobNoLimit: 2,
    added: false,
    addressesFrom: [
      {
        locationId: "d-company",
        odds: 5
      },
      {
        locationId: "home",
        odds: 2
      },
    ],
    addressesTo: [
      {
        locationId: "ten-5",
        odds: 1
      },
      {
        locationId: "ten-6",
        odds: 1
      },
      {
        locationId: "ten-7",
        odds: 1
      },
      {
        locationId: "ten-8",
        odds: 1
      },
      {
        locationId: "ten-9",
        odds: 1
      },
      {
        locationId: "ten-10",
        odds: 1
      },
      {
        locationId: "ten-11",
        odds: 1
      },
      {
        locationId: "ten-12",
        odds: 1
      },
      {
        locationId: "ten-13",
        odds: 1
      },
      {
        locationId: "ten-14",
        odds: 1
      },
      {
        locationId: "ten-15",
        odds: 1
      },
      {
        locationId: "greater-5",
        odds: 1
      },
      {
        locationId: "greater-6",
        odds: 1
      },
      {
        locationId: "greater-7",
        odds: 1
      },
      {
        locationId: "greater-8",
        odds: 1
      },
      {
        locationId: "greater-9",
        odds: 1
      },
      {
        locationId: "greater-10",
        odds: 1
      },
      {
        locationId: "greater-11",
        odds: 1
      },
      {
        locationId: "greater-12",
        odds: 1
      },
      {
        locationId: "greater-13",
        odds: 1
      },
      {
        locationId: "greater-14",
        odds: 1
      },
      {
        locationId: "greater-15",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 31 * MIN_MULTIPLIER,
      },
      {
        id: "2",
        type: "scheduled",
        schedule: 47 * MIN_MULTIPLIER,
      },
      {
        id: "3",
        type: "random",
        schedule: 53 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 360 * MIN_MULTIPLIER,
        timeLimit: 120 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: ENVELOPE,
        odds: 1
      },
      {
        itemId: CONTRACT,
        odds: 1
      },
      {
        itemId: LEVER_ARCH_FILE,
        odds: 1
      },
      {
        itemId: KEYS,
        odds: 1
      },
      {
        itemId: SECTION_21,
        odds: 1
      },
    ],
    fineRate: 0.1
  },
  {
    id: "5",
    name: "Elizabeth Opera House",
    description: "Opera company",
    street: locations[5].street,
    shortPostcode: locations[5].shortPostcode,
    jobNoLimit: 2,
    added: false,
    addressesFrom: [
      {
        locationId: "e-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "ten-1",
        odds: 1
      },
      {
        locationId: "ten-37",
        odds: 1
      },
      {
        locationId: "ten-11",
        odds: 1
      },
      {
        locationId: "ten-21",
        odds: 1
      },
      {
        locationId: "ten-31",
        odds: 1
      },
      {
        locationId: "ten-41",
        odds: 1
      },
      {
        locationId: "ten-2",
        odds: 1
      },
      {
        locationId: "ten-12",
        odds: 1
      },
      {
        locationId: "ten-22",
        odds: 1
      },
      {
        locationId: "ten-32",
        odds: 1
      },
      {
        locationId: "ten-42",
        odds: 1
      },
      {
        locationId: "greater-10",
        odds: 1
      },
      {
        locationId: "greater-11",
        odds: 1
      },
      {
        locationId: "greater-12",
        odds: 1
      },
      {
        locationId: "greater-13",
        odds: 1
      },
      {
        locationId: "greater-14",
        odds: 1
      },
      {
        locationId: "greater-15",
        odds: 1
      },
      {
        locationId: "greater-16",
        odds: 1
      },
      {
        locationId: "greater-17",
        odds: 1
      },
      {
        locationId: "greater-18",
        odds: 1
      },
      {
        locationId: "greater-19",
        odds: 1
      },
      {
        locationId: "greater-20",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 30 * MIN_MULTIPLIER
      },
      {
        id: "2",
        type: "schedule",
        schedule: 37 * MIN_MULTIPLIER
      },
    ],
    items: [
      {
        itemId: MUSIC_SCORE,
        odds: 15
      },
      {
        itemId: OPERA_COSTUME,
        odds: 5
      },
      {
        itemId: OPERA_SHOES,
        odds: 5
      },
      {
        itemId: OPERA_GLASSES,
        odds: 3
      },
      {
        itemId: OPERA_MASK,
        odds: 3
      },
      {
        itemId: CONDUCTOR_BATON,
        odds: 1
      },
      {
        itemId: SUPERTITLE_SCREEN,
        odds: 1
      },
      {
        itemId: OPERA_SCENERY,
        odds: 2,
      },
      {
        itemId: SPOTLIGHT,
        odds: 2
      },
      {
        itemId: STAGE_WEIGHT,
        odds: 2
      },
      {
        itemId: STAGE_CURTAINS,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "6",
    name: "FlexTow and Co",
    description: "Construction equipment",
    street: locations[6].street,
    shortPostcode: locations[6].shortPostcode,
    jobNoLimit: 2,
    added: false,
    addressesFrom: [
      {
        locationId: "f-company",
        odds: 1
      },
    ],
    addressesTo: [
      {
        locationId: "ten-25",
        odds: 1
      },
      {
        locationId: "ten-26",
        odds: 1
      },
      {
        locationId: "ten-27",
        odds: 1
      },
      {
        locationId: "ten-28",
        odds: 1
      },
      {
        locationId: "ten-29",
        odds: 1
      },
      {
        locationId: "ten-30",
        odds: 1
      },
      {
        locationId: "ten-31",
        odds: 1
      },
      {
        locationId: "ten-32",
        odds: 1
      },
      {
        locationId: "ten-33",
        odds: 1
      },
      {
        locationId: "ten-34",
        odds: 1
      },
      {
        locationId: "ten-35",
        odds: 1
      },
      {
        locationId: "ten-36",
        odds: 1
      },
      {
        locationId: "ten-37",
        odds: 1
      },
      {
        locationId: "ten-38",
        odds: 1
      },
      {
        locationId: "ten-39",
        odds: 1
      },
      {
        locationId: "ten-40",
        odds: 1
      },
      {
        locationId: "ten-41",
        odds: 1
      },
      {
        locationId: "ten-42",
        odds: 1
      },
      {
        locationId: "ten-43",
        odds: 1
      },
      {
        locationId: "ten-44",
        odds: 1
      },
      {
        locationId: "ten-45",
        odds: 1
      },
      {
        locationId: "ten-46",
        odds: 1
      },
      {
        locationId: "ten-47",
        odds: 1
      },
      {
        locationId: "ten-48",
        odds: 1
      },
      {
        locationId: "ten-49",
        odds: 1
      },
      {
        locationId: "ten-50",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 26 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 390 * MIN_MULTIPLIER,
        timeLimit: 90 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: CONTRACT,
        odds: 20
      },
      {
        itemId: INVOICE,
        odds: 1
      },
    ],
    fineRate: 0.5
  },
  {
    id: "7",
    name: "Genner PR",
    description: "Unforgettable advertising campaigns",
    street: locations[7].street,
    shortPostcode: locations[7].shortPostcode,
    jobNoLimit: 2,
    added: false,
    addressesFrom: [
      {
        locationId: "g-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "pr-15",
        odds: 1
      },
      {
        locationId: "pr-16",
        odds: 1
      },
      {
        locationId: "pr-17",
        odds: 1
      },
      {
        locationId: "pr-18",
        odds: 1
      },
      {
        locationId: "pr-19",
        odds: 1
      },
      {
        locationId: "pr-20",
        odds: 1
      },
      {
        locationId: "pr-21",
        odds: 1
      },
      {
        locationId: "pr-22",
        odds: 1
      },
      {
        locationId: "pr-23",
        odds: 1
      },
      {
        locationId: "pr-24",
        odds: 1
      },
      {
        locationId: "pr-25",
        odds: 1
      },
      {
        locationId: "pr-26",
        odds: 1
      },
      {
        locationId: "pr-27",
        odds: 1
      },
      {
        locationId: "pr-28",
        odds: 1
      },
      {
        locationId: "pr-29",
        odds: 1
      },
      {
        locationId: "pr-30",
        odds: 1
      },
      {
        locationId: "pr-31",
        odds: 1
      },
      {
        locationId: "pr-32",
        odds: 1
      },
      {
        locationId: "pr-33",
        odds: 1
      },
      {
        locationId: "pr-34",
        odds: 1
      },
      {
        locationId: "pr-35",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 10 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 120 * MIN_MULTIPLIER,
      },
      {
        id: "2",
        type: "scheduled",
        schedule: 20 * MIN_MULTIPLIER,
        startTick: 120 * MIN_MULTIPLIER,
        endTick: 240 * MIN_MULTIPLIER,
      },
      {
        id: "3",
        type: "scheduled",
        schedule: 30 * MIN_MULTIPLIER,
        startTick: 240 * MIN_MULTIPLIER,
        endTick: 450 * MIN_MULTIPLIER,
      },
      {
        id: "4",
        type: "scheduled",
        schedule: 10 * MIN_MULTIPLIER,
        startTick: 420 * MIN_MULTIPLIER,
        endTick: 450 * MIN_MULTIPLIER,
        timeLimit: 10 * MIN_MULTIPLIER
      },
    ],
    items: [
      {
        itemId: INVOICE,
        odds: 5
      },
      {
        itemId: COLA,
        odds: 1
      },
      {
        itemId: PLACARD,
        odds: 1
      },
      {
        itemId: P45,
        odds: 1
      },
    ],
    fineRate: 0.9
  },
  {
    id: "8",
    name: "Hutz Law",
    description: "For all your legal needs",
    street: locations[8].street,
    shortPostcode: locations[8].shortPostcode,
    jobNoLimit: 2,
    added: false,
    addressesFrom: [
      {
        locationId: "h-company",
        odds: 1
      },
    ],
    addressesTo: [
      {
        locationId: "ten-1",
        odds: 1
      },
      {
        locationId: "ten-2",
        odds: 1
      },
      {
        locationId: "ten-3",
        odds: 1
      },
      {
        locationId: "ten-4",
        odds: 1
      },
      {
        locationId: "ten-5",
        odds: 1
      },
      {
        locationId: "ten-6",
        odds: 1
      },
      {
        locationId: "ten-7",
        odds: 1
      },
      {
        locationId: "ten-8",
        odds: 1
      },
      {
        locationId: "ten-9",
        odds: 1
      },
      {
        locationId: "ten-10",
        odds: 1
      },
      {
        locationId: "ten-11",
        odds: 1
      },
      {
        locationId: "ten-12",
        odds: 1
      },
      {
        locationId: "ten-13",
        odds: 1
      },
      {
        locationId: "ten-14",
        odds: 1
      },
      {
        locationId: "ten-15",
        odds: 1
      },
      {
        locationId: "ten-16",
        odds: 1
      },
      {
        locationId: "ten-17",
        odds: 1
      },
      {
        locationId: "ten-18",
        odds: 1
      },
      {
        locationId: "ten-19",
        odds: 1
      },
      {
        locationId: "ten-20",
        odds: 1
      },
      {
        locationId: "ten-21",
        odds: 1
      },
      {
        locationId: "ten-22",
        odds: 1
      },
      {
        locationId: "ten-23",
        odds: 1
      },
      {
        locationId: "ten-24",
        odds: 1
      },
      {
        locationId: "ten-25",
        odds: 1
      },
      {
        locationId: "ten-26",
        odds: 1
      },
      {
        locationId: "ten-27",
        odds: 1
      },
      {
        locationId: "ten-28",
        odds: 1
      },
      {
        locationId: "ten-29",
        odds: 1
      },
      {
        locationId: "ten-30",
        odds: 1
      },
      {
        locationId: "ten-31",
        odds: 1
      },
      {
        locationId: "ten-32",
        odds: 1
      },
      {
        locationId: "ten-33",
        odds: 1
      },
      {
        locationId: "ten-34",
        odds: 1
      },
      {
        locationId: "ten-35",
        odds: 1
      },
      {
        locationId: "ten-36",
        odds: 1
      },
      {
        locationId: "ten-37",
        odds: 1
      },
      {
        locationId: "ten-38",
        odds: 1
      },
      {
        locationId: "ten-39",
        odds: 1
      },
      {
        locationId: "ten-40",
        odds: 1
      },
      {
        locationId: "ten-41",
        odds: 1
      },
      {
        locationId: "ten-42",
        odds: 1
      },
      {
        locationId: "ten-43",
        odds: 1
      },
      {
        locationId: "ten-44",
        odds: 1
      },
      {
        locationId: "ten-45",
        odds: 1
      },
      {
        locationId: "ten-46",
        odds: 1
      },
      {
        locationId: "ten-47",
        odds: 1
      },
      {
        locationId: "ten-48",
        odds: 1
      },
      {
        locationId: "ten-49",
        odds: 1
      },
      {
        locationId: "ten-50",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 19 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
        timeLimit: 360 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: CHEQUE,
        odds: 1
      },
    ],
    fineRate: -0.1
  },
  {
    id: "9",
    name: "Insidesys",
    description: "Reputable family run IT company",
    street: locations[9].street,
    shortPostcode: locations[9].shortPostcode,
    jobNoLimit: 3,
    added: false,
    addressesFrom: [
      {
        locationId: "i-company",
        odds: 1
      },
    ],
    addressesTo: [
      {
        locationId: "ten-51",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 20 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
        timeLimit: 60 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: LARGE_HEAVY_BROWN_ENVELOPE,
        odds: 15
      },
      {
        itemId: CONTRACT,
        odds: 5
      },
      {
        itemId: CASE_OF_CHAMPAGNE,
        odds: 3
      },
      {
        itemId: HAMPER,
        odds: 2
      },
      {
        itemId: FABERGE_EGG,
        odds: 1
      },
    ],
    fineRate: 0.25
  },
  {
    id: "10",
    name: "JS Advertising",
    description: "Contemporary advertisting agency",
    street: locations[10].street,
    shortPostcode: locations[10].shortPostcode,
    jobNoLimit: 3,
    added: false,
    addressesFrom: [
      {
        locationId: "ten-10",
        odds: 1
      },
      {
        locationId: "ten-11",
        odds: 1
      },
      {
        locationId: "ten-12",
        odds: 1
      },
      {
        locationId: "ten-13",
        odds: 1
      },
      {
        locationId: "ten-14",
        odds: 1
      },
      {
        locationId: "ten-16",
        odds: 1
      },
      {
        locationId: "ten-17",
        odds: 1
      },
      {
        locationId: "ten-18",
        odds: 1
      },
      {
        locationId: "ten-19",
        odds: 1
      },
      {
        locationId: "ten-20",
        odds: 1
      },
      {
        locationId: "ten-21",
        odds: 1
      },
      {
        locationId: "ten-22",
        odds: 1
      },
      {
        locationId: "ten-23",
        odds: 1
      },
      {
        locationId: "ten-24",
        odds: 1
      },
      {
        locationId: "ten-26",
        odds: 1
      },
      {
        locationId: "ten-27",
        odds: 1
      },
      {
        locationId: "ten-28",
        odds: 1
      },
      {
        locationId: "ten-29",
        odds: 1
      },
      {
        locationId: "ten-30",
        odds: 1
      },
    ],
    addressesTo: [
      {
        locationId: "ten-5",
        odds: 6
      },
      {
        locationId: "ten-15",
        odds: 5
      },
      {
        locationId: "ten-25",
        odds: 4
      },
      {
        locationId: "ten-35",
        odds: 3
      },
      {
        locationId: "ten-45",
        odds: 2
      },
      {
        locationId: "j-company",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 20 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 45 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: CASE_OF_WINE,
        odds: 20
      },
      {
        itemId: HAMBURGERS,
        odds: 15
      },
      {
        itemId: SPACE_INVADERS,
        odds: 2
      },
      {
        itemId: CONTRACT,
        odds: 1
      },
    ],
    fineRate: 0.1
  },
  {
    id: "11",
    name: "Krusty Toys",
    description: "Fun branded produce",
    street: locations[11].street,
    shortPostcode: locations[11].shortPostcode,
    jobNoLimit: 3,
    added: false,
    addressesFrom: [
      {
        locationId: "k-company",
        odds: 1
      },
    ],
    addressesTo: [
      {
        locationId: "ten-4",
        odds: 1
      },
      {
        locationId: "ten-14",
        odds: 1
      },
      {
        locationId: "ten-24",
        odds: 1
      },
      {
        locationId: "ten-34",
        odds: 1
      },
      {
        locationId: "ten-44",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 27 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 60 * MIN_MULTIPLIER,
      },
      {
        id: "2",
        type: "random",
        schedule: 31 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 60 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: BRAND_ENDORSEMENT_CONTRACT,
        odds: 1
      },
      {
        itemId: LEGAL_FORMS,
        odds: 1
      },
      {
        itemId: PHONE,
        odds: 1
      },
      {
        itemId: SPACE_PROBE,
        odds: 1
      },
      {
        itemId: CALCULATOR,
        odds: 1
      },
      {
        itemId: CEREAL,
        odds: 1
      },
      {
        itemId: RELIGIOUS_BISCUITS,
        odds: 1
      },
      {
        itemId: MONKEY_BITES,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "12",
    name: "Lower Lip News",
    description: "Rib tickling commentary",
    street: locations[12].street,
    shortPostcode: locations[12].shortPostcode,
    jobNoLimit: 3,
    added: false,
    addressesFrom: [
      {
        locationId: "l-company",
        odds: 1
      },
    ],
    addressesTo: [
      {
        locationId: "ten-51",
        odds: 1
      },
      {
        locationId: "i-company",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 19 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
        timeLimit: 420 * MIN_MULTIPLIER,
      }
    ],
    items: [
      {
        itemId: FLOWERS,
        odds: 1
      },
      {
        itemId: BALLOONS,
        odds: 1
      },
    ],
    fineRate: 0.01
  },
  {
    id: "13",
    name: "Marlon films",
    description: "Mind-blowing film production",
    street: locations[13].street,
    shortPostcode: locations[13].shortPostcode,
    jobNoLimit: 3,
    added: false,
    addressesFrom: [
      {
        locationId: "m-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "ten-1",
        odds: 1
      },
      {
        locationId: "ten-7",
        odds: 1
      },
      {
        locationId: "ten-13",
        odds: 1
      },
      {
        locationId: "ten-19",
        odds: 1
      },
      {
        locationId: "ten-25",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 61 * MIN_MULTIPLIER,
      },
      {
        id: "2",
        type: "random",
        schedule: 59 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 450 * MIN_MULTIPLIER,
        timeLimit: 60 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: FILM_REEL,
        odds: 10
      },
      {
        itemId: FILM_CAMERA,
        odds: 4
      },
      // { @todo - not Marlon film appropriate, move to another film company
      //   itemId: ELEPHANT_STATUE,
      //   odds: 1
      // },
      // {
      //   itemId: STUFFED_ANIMAL,
      //   odds: 1
      // },
      {
        itemId: SUBMARINE,
        odds: 1
      },
      {
        itemId: ORANGES,
        odds: 1
      },
      {
        itemId: FLAMINGOES,
        odds: 1
      },
      {
        itemId: PRUSSIAN_WAISTCOAT,
        odds: 1
      },
      {
        itemId: DIRECTORS_CHAIR,
        odds: 1
      },
      {
        itemId: SPACESHIP,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "14",
    name: "Nathan Consulting",
    description: "Sound business advice",
    street: locations[14].street,
    shortPostcode: locations[14].shortPostcode,
    jobNoLimit: 4,
    added: false,
    addressesFrom: [
      {
        locationId: "n-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "a-company",
        odds: 1
      },
      {
        locationId: "b-company",
        odds: 1
      },
      {
        locationId: "c-company",
        odds: 1
      },
      {
        locationId: "d-company",
        odds: 1
      },
      {
        locationId: "e-company",
        odds: 1
      },
      {
        locationId: "f-company",
        odds: 1
      },
      {
        locationId: "g-company",
        odds: 1
      },
      {
        locationId: "h-company",
        odds: 1
      },
      {
        locationId: "i-company",
        odds: 1
      },
      {
        locationId: "j-company",
        odds: 1
      },
      {
        locationId: "k-company",
        odds: 1
      },
      {
        locationId: "l-company",
        odds: 1
      },
      {
        locationId: "m-company",
        odds: 1
      },
      {
        locationId: "n-company",
        odds: 1
      },
      {
        locationId: "o-company",
        odds: 1
      },
      {
        locationId: "p-company",
        odds: 1
      },
      {
        locationId: "q-company",
        odds: 1
      },
      {
        locationId: "r-company",
        odds: 1
      },
      {
        locationId: "s-company",
        odds: 1
      },
      {
        locationId: "t-company",
        odds: 1
      },
      {
        locationId: "u-company",
        odds: 1
      },
      {
        locationId: "v-company",
        odds: 1
      },
      {
        locationId: "w-company",
        odds: 1
      },
      {
        locationId: "x-company",
        odds: 1
      },
      {
        locationId: "y-company",
        odds: 1
      },
      {
        locationId: "z-company",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 67 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 57 * MIN_MULTIPLIER,
      },
      {
        id: "2",
        type: "random",
        schedule: 63 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 59 * MIN_MULTIPLIER,
      },
      {
        id: "3",
        type: "random",
        schedule: 61 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 61 * MIN_MULTIPLIER,
      },
      {
        id: "4",
        type: "random",
        schedule: 59 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 63 * MIN_MULTIPLIER,
      },
      {
        id: "5",
        type: "random",
        schedule: 57 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 67 * MIN_MULTIPLIER,
      },
      {
        id: "6",
        type: "random",
        schedule: 53 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 71 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: PITCH_DECK,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "15",
    name: "Oblomov Fashion",
    description: "High street fashion",
    street: locations[15].street,
    shortPostcode: locations[15].shortPostcode,
    jobNoLimit: 4,
    added: false,
    addressesFrom: [
      {
        locationId: "o-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "fashion-25",
        odds: 1
      },
      {
        locationId: "fashion-26",
        odds: 1
      },
      {
        locationId: "fashion-27",
        odds: 1
      },
      {
        locationId: "fashion-28",
        odds: 1
      },
      {
        locationId: "fashion-29",
        odds: 1
      },
      {
        locationId: "fashion-30",
        odds: 1
      },
      {
        locationId: "fashion-31",
        odds: 1
      },
      {
        locationId: "fashion-32",
        odds: 1
      },
      {
        locationId: "fashion-33",
        odds: 1
      },
      {
        locationId: "fashion-34",
        odds: 1
      },
      {
        locationId: "fashion-35",
        odds: 1
      },
      {
        locationId: "fashion-36",
        odds: 1
      },
      {
        locationId: "fashion-37",
        odds: 1
      },
      {
        locationId: "fashion-38",
        odds: 1
      },
      {
        locationId: "fashion-39",
        odds: 1
      },
      {
        locationId: "fashion-40",
        odds: 1
      },
      {
        locationId: "fashion-41",
        odds: 1
      },
      {
        locationId: "fashion-42",
        odds: 1
      },
      {
        locationId: "fashion-43",
        odds: 1
      },
      {
        locationId: "fashion-44",
        odds: 1
      },
      {
        locationId: "fashion-45",
        odds: 1
      },
      {
        locationId: "fashion-46",
        odds: 1
      },
      {
        locationId: "fashion-47",
        odds: 1
      },
      {
        locationId: "fashion-48",
        odds: 1
      },
      {
        locationId: "fashion-49",
        odds: 1
      },
      {
        locationId: "fashion-50",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 21 * MIN_MULTIPLIER
      },
    ],
    items: [
      {
        itemId: HAT,
        odds: 1
      },
      {
        itemId: COAT,
        odds: 1
      },
      {
        itemId: GLOVES,
        odds: 1
      },
      {
        itemId: SHOES,
        odds: 1
      },
      {
        itemId: BELT,
        odds: 1
      },
      {
        itemId: CLOTHES_RACK,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "16",
    name: "Paddington Comestibles",
    description: "Purveyor of fine sandwiches",
    street: locations[16].street,
    shortPostcode: locations[16].shortPostcode,
    jobNoLimit: 4,
    added: false,
    addressesFrom: [
      {
        locationId: "p-company",
        odds: 5
      },
    ],
    addressesTo: [ // Just because they're not being used much
    {
      locationId: "fashion-20",
      odds: 1
    },
    {
      locationId: "fashion-21",
      odds: 1
    },
    {
      locationId: "fashion-22",
      odds: 1
    },
    {
      locationId: "fashion-23",
      odds: 1
    },
    {
      locationId: "fashion-24",
      odds: 1
    },
    {
      locationId: "fashion-25",
      odds: 1
    },
    {
      locationId: "fashion-26",
      odds: 1
    },
    {
      locationId: "fashion-27",
      odds: 1
    },
    {
      locationId: "fashion-28",
      odds: 1
    },
    {
      locationId: "fashion-29",
      odds: 1
    },
    {
      locationId: "fashion-30",
      odds: 1
    },
    {
      locationId: "fashion-31",
      odds: 1
    },
    {
      locationId: "fashion-32",
      odds: 1
    },
    {
      locationId: "fashion-33",
      odds: 1
    },
    {
      locationId: "fashion-34",
      odds: 1
    },
    {
      locationId: "fashion-35",
      odds: 1
    },
    {
      locationId: "fashion-36",
      odds: 1
    },
    {
      locationId: "fashion-37",
      odds: 1
    },
    {
      locationId: "fashion-38",
      odds: 1
    },
    {
      locationId: "fashion-39",
      odds: 1
    },
    {
      locationId: "fashion-40",
      odds: 1
    },
    {
      locationId: "fashion-41",
      odds: 1
    },
    {
      locationId: "fashion-42",
      odds: 1
    },
    {
      locationId: "fashion-43",
      odds: 1
    },
    {
      locationId: "fashion-44",
      odds: 1
    },
    {
      locationId: "fashion-45",
      odds: 1
    },
  ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 23 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: SANDWICH,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "17",
    name: "Qubert Games",
    description: "Crafters of obscure web based business games",
    street: locations[17].street,
    shortPostcode: locations[17].shortPostcode,
    jobNoLimit: 4,
    added: false,
    addressesFrom: [
      {
        locationId: "q-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "ten-1",
        odds: 1
      },
      {
        locationId: "ten-2",
        odds: 1
      },
      {
        locationId: "ten-3",
        odds: 1
      },
      {
        locationId: "ten-4",
        odds: 1
      },
      {
        locationId: "ten-5",
        odds: 1
      },
      {
        locationId: "ten-6",
        odds: 1
      },
      {
        locationId: "ten-7",
        odds: 1
      },
      {
        locationId: "ten-8",
        odds: 1
      },
      {
        locationId: "ten-9",
        odds: 1
      },
      {
        locationId: "ten-10",
        odds: 1
      },
      {
        locationId: "ten-11",
        odds: 1
      },
      {
        locationId: "ten-12",
        odds: 1
      },
      {
        locationId: "ten-13",
        odds: 1
      },
      {
        locationId: "ten-14",
        odds: 1
      },
      {
        locationId: "ten-15",
        odds: 1
      },
      {
        locationId: "ten-16",
        odds: 1
      },
      {
        locationId: "ten-17",
        odds: 1
      },
      {
        locationId: "ten-18",
        odds: 1
      },
      {
        locationId: "ten-19",
        odds: 1
      },
      {
        locationId: "ten-20",
        odds: 1
      },
      {
        locationId: "ten-21",
        odds: 1
      },
      {
        locationId: "ten-22",
        odds: 1
      },
      {
        locationId: "ten-23",
        odds: 1
      },
      {
        locationId: "ten-24",
        odds: 1
      },
      {
        locationId: "ten-25",
        odds: 1
      },
      {
        locationId: "ten-26",
        odds: 1
      },
      {
        locationId: "ten-27",
        odds: 1
      },
      {
        locationId: "ten-28",
        odds: 1
      },
      {
        locationId: "ten-29",
        odds: 1
      },
      {
        locationId: "ten-30",
        odds: 1
      },
      {
        locationId: "ten-31",
        odds: 1
      },
      {
        locationId: "ten-32",
        odds: 1
      },
      {
        locationId: "ten-33",
        odds: 1
      },
      {
        locationId: "ten-34",
        odds: 1
      },
      {
        locationId: "ten-35",
        odds: 1
      },
      {
        locationId: "ten-36",
        odds: 1
      },
      {
        locationId: "ten-37",
        odds: 1
      },
      {
        locationId: "ten-38",
        odds: 1
      },
      {
        locationId: "ten-39",
        odds: 1
      },
      {
        locationId: "ten-40",
        odds: 1
      },
      {
        locationId: "ten-41",
        odds: 1
      },
      {
        locationId: "ten-42",
        odds: 1
      },
      {
        locationId: "ten-43",
        odds: 1
      },
      {
        locationId: "ten-44",
        odds: 1
      },
      {
        locationId: "ten-45",
        odds: 1
      },
      {
        locationId: "ten-46",
        odds: 1
      },
      {
        locationId: "ten-47",
        odds: 1
      },
      {
        locationId: "ten-48",
        odds: 1
      },
      {
        locationId: "ten-49",
        odds: 1
      },
      {
        locationId: "ten-50",
        odds: 1
      },
      {
        locationId: "ten-51",
        odds: 1
      },
      {
        locationId: "pr-1",
        odds: 1
      },
      {
        locationId: "pr-2",
        odds: 1
      },
      {
        locationId: "pr-3",
        odds: 1
      },
      {
        locationId: "pr-4",
        odds: 1
      },
      {
        locationId: "pr-5",
        odds: 1
      },
      {
        locationId: "pr-6",
        odds: 1
      },
      {
        locationId: "pr-7",
        odds: 1
      },
      {
        locationId: "pr-8",
        odds: 1
      },
      {
        locationId: "pr-9",
        odds: 1
      },
      {
        locationId: "pr-10",
        odds: 1
      },
      {
        locationId: "pr-11",
        odds: 1
      },
      {
        locationId: "pr-12",
        odds: 1
      },
      {
        locationId: "pr-13",
        odds: 1
      },
      {
        locationId: "pr-14",
        odds: 1
      },
      {
        locationId: "pr-15",
        odds: 1
      },
      {
        locationId: "pr-16",
        odds: 1
      },
      {
        locationId: "pr-17",
        odds: 1
      },
      {
        locationId: "pr-18",
        odds: 1
      },
      {
        locationId: "pr-19",
        odds: 1
      },
      {
        locationId: "pr-20",
        odds: 1
      },
      {
        locationId: "pr-21",
        odds: 1
      },
      {
        locationId: "pr-22",
        odds: 1
      },
      {
        locationId: "pr-23",
        odds: 1
      },
      {
        locationId: "pr-24",
        odds: 1
      },
      {
        locationId: "pr-25",
        odds: 1
      },
      {
        locationId: "pr-26",
        odds: 1
      },
      {
        locationId: "pr-27",
        odds: 1
      },
      {
        locationId: "pr-28",
        odds: 1
      },
      {
        locationId: "pr-29",
        odds: 1
      },
      {
        locationId: "pr-30",
        odds: 1
      },
      {
        locationId: "pr-31",
        odds: 1
      },
      {
        locationId: "pr-32",
        odds: 1
      },
      {
        locationId: "pr-33",
        odds: 1
      },
      {
        locationId: "pr-34",
        odds: 1
      },
      {
        locationId: "pr-35",
        odds: 1
      },
      {
        locationId: "fashion-1",
        odds: 3
      },
      {
        locationId: "fashion-2",
        odds: 2
      },
      {
        locationId: "fashion-3",
        odds: 2
      },
      {
        locationId: "fashion-4",
        odds: 1
      },
      {
        locationId: "fashion-5",
        odds: 1
      },
      {
        locationId: "fashion-6",
        odds: 1
      },
      {
        locationId: "fashion-7",
        odds: 1
      },
      {
        locationId: "fashion-8",
        odds: 1
      },
      {
        locationId: "fashion-9",
        odds: 1
      },
      {
        locationId: "fashion-10",
        odds: 2
      },
      {
        locationId: "fashion-11",
        odds: 2
      },
      {
        locationId: "fashion-12",
        odds: 2
      },
      {
        locationId: "fashion-13",
        odds: 2
      },
      {
        locationId: "fashion-14",
        odds: 1
      },
      {
        locationId: "fashion-15",
        odds: 1
      },
      {
        locationId: "fashion-16",
        odds: 1
      },
      {
        locationId: "fashion-17",
        odds: 1
      },
      {
        locationId: "fashion-18",
        odds: 1
      },
      {
        locationId: "fashion-19",
        odds: 1
      },
      {
        locationId: "fashion-20",
        odds: 1
      },
      {
        locationId: "fashion-21",
        odds: 1
      },
      {
        locationId: "fashion-22",
        odds: 1
      },
      {
        locationId: "fashion-23",
        odds: 1
      },
      {
        locationId: "fashion-24",
        odds: 1
      },
      {
        locationId: "fashion-25",
        odds: 1
      },
      {
        locationId: "fashion-26",
        odds: 1
      },
      {
        locationId: "fashion-27",
        odds: 1
      },
      {
        locationId: "fashion-28",
        odds: 1
      },
      {
        locationId: "fashion-29",
        odds: 1
      },
      {
        locationId: "fashion-30",
        odds: 1
      },
      {
        locationId: "fashion-31",
        odds: 1
      },
      {
        locationId: "fashion-32",
        odds: 1
      },
      {
        locationId: "fashion-33",
        odds: 1
      },
      {
        locationId: "fashion-34",
        odds: 1
      },
      {
        locationId: "fashion-35",
        odds: 1
      },
      {
        locationId: "fashion-36",
        odds: 1
      },
      {
        locationId: "fashion-37",
        odds: 1
      },
      {
        locationId: "fashion-38",
        odds: 1
      },
      {
        locationId: "fashion-39",
        odds: 1
      },
      {
        locationId: "fashion-40",
        odds: 1
      },
      {
        locationId: "fashion-41",
        odds: 1
      },
      {
        locationId: "fashion-42",
        odds: 1
      },
      {
        locationId: "fashion-43",
        odds: 1
      },
      {
        locationId: "fashion-44",
        odds: 1
      },
      {
        locationId: "fashion-45",
        odds: 1
      },
      {
        locationId: "fashion-46",
        odds: 1
      },
      {
        locationId: "fashion-47",
        odds: 1
      },
      {
        locationId: "fashion-48",
        odds: 1
      },
      {
        locationId: "fashion-49",
        odds: 1
      },
      {
        locationId: "fashion-50",
        odds: 1
      },
      {
        locationId: "greater-1",
        odds: 1
      },
      {
        locationId: "greater-2",
        odds: 1
      },
      {
        locationId: "greater-3",
        odds: 1
      },
      {
        locationId: "greater-4",
        odds: 1
      },
      {
        locationId: "greater-5",
        odds: 1
      },
      {
        locationId: "greater-6",
        odds: 1
      },
      {
        locationId: "greater-7",
        odds: 1
      },
      {
        locationId: "greater-8",
        odds: 1
      },
      {
        locationId: "greater-9",
        odds: 1
      },
      {
        locationId: "greater-10",
        odds: 1
      },
      {
        locationId: "greater-11",
        odds: 1
      },
      {
        locationId: "greater-12",
        odds: 1
      },
      {
        locationId: "greater-13",
        odds: 1
      },
      {
        locationId: "greater-14",
        odds: 1
      },
      {
        locationId: "greater-15",
        odds: 1
      },
      {
        locationId: "greater-16",
        odds: 1
      },
      {
        locationId: "greater-17",
        odds: 1
      },
      {
        locationId: "greater-18",
        odds: 1
      },
      {
        locationId: "greater-19",
        odds: 1
      },
      {
        locationId: "greater-20",
        odds: 1
      },
      {
        locationId: "greater-21",
        odds: 1
      },
      {
        locationId: "greater-22",
        odds: 1
      },
      {
        locationId: "greater-23",
        odds: 1
      },
      {
        locationId: "greater-24",
        odds: 1
      },
      {
        locationId: "greater-25",
        odds: 1
      },
      {
        locationId: "home",
        odds: 1
      },
      {
        locationId: "tech",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 27 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER
      },
      {
        id: "2",
        type: "random",
        schedule: 23 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER
      },
      {
        id: "3",
        type: "random",
        schedule: 27 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
        timeLimit: 480 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: CONTRACT,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "18",
    name: "Rumpole & Partners",
    description: "Traditional law firm",
    street: locations[18].street,
    shortPostcode: locations[18].shortPostcode,
    jobNoLimit: 4,
    added: false,
    addressesFrom: [
      {
        locationId: "r-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "ten-10",
        odds: 1
      },
      {
        locationId: "ten-11",
        odds: 1
      },
      {
        locationId: "ten-12",
        odds: 1
      },
      {
        locationId: "ten-13",
        odds: 1
      },
      {
        locationId: "ten-14",
        odds: 1
      },
      {
        locationId: "ten-15",
        odds: 1
      },
      {
        locationId: "ten-16",
        odds: 1
      },
      {
        locationId: "ten-17",
        odds: 1
      },
      {
        locationId: "ten-18",
        odds: 1
      },
      {
        locationId: "ten-19",
        odds: 1
      },
      {
        locationId: "ten-20",
        odds: 1
      },
      {
        locationId: "ten-21",
        odds: 1
      },
      {
        locationId: "ten-22",
        odds: 1
      },
      {
        locationId: "ten-23",
        odds: 1
      },
      {
        locationId: "ten-24",
        odds: 1
      },
      {
        locationId: "ten-25",
        odds: 1
      },
      {
        locationId: "ten-26",
        odds: 1
      },
      {
        locationId: "ten-27",
        odds: 1
      },
      {
        locationId: "ten-28",
        odds: 1
      },
      {
        locationId: "ten-29",
        odds: 1
      },
      {
        locationId: "ten-30",
        odds: 1
      },
      {
        locationId: "ten-31",
        odds: 1
      },
      {
        locationId: "ten-32",
        odds: 1
      },
      {
        locationId: "ten-33",
        odds: 1
      },
      {
        locationId: "ten-34",
        odds: 1
      },
      {
        locationId: "ten-35",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 25 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 450 * MIN_MULTIPLIER
      },
    ],
    items: [
      {
        itemId: LEVER_ARCH_FILE,
        odds: 1
      },
      {
        itemId: BOTTLE_OF_WINE,
        odds: 1
      },
      {
        itemId: CIGARS,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "19",
    name: "Saycheese and Lull",
    description: "Law firm",
    street: locations[19].street,
    shortPostcode: locations[19].shortPostcode,
    jobNoLimit: 5,
    added: false,
    addressesFrom: [
      {
        locationId: "s-company",
        odds: 1
      },
    ],
    addressesTo: [
      {
        locationId: "ten-1",
        odds: 1
      },
      {
        locationId: "ten-2",
        odds: 1
      },
      {
        locationId: "ten-3",
        odds: 1
      },
      {
        locationId: "ten-11",
        odds: 1
      },
      {
        locationId: "ten-12",
        odds: 1
      },
      {
        locationId: "ten-13",
        odds: 1
      },
      {
        locationId: "ten-21",
        odds: 1
      },
      {
        locationId: "ten-22",
        odds: 1
      },
      {
        locationId: "ten-23",
        odds: 1
      },
      {
        locationId: "ten-31",
        odds: 1
      },
      {
        locationId: "ten-32",
        odds: 1
      },
      {
        locationId: "ten-33",
        odds: 1
      },
      {
        locationId: "ten-41",
        odds: 1
      },
      {
        locationId: "ten-42",
        odds: 1
      },
      {
        locationId: "ten-43",
        odds: 1
      },
    ],
    jobRules: [ // Stops for lunch
      {
        id: "1",
        type: "scheduled",
        schedule: 5 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 180 * MIN_MULTIPLIER
      },
      {
        id: "1",
        type: "scheduled",
        schedule: 5 * MIN_MULTIPLIER,
        startTick: 240 * MIN_MULTIPLIER,
        endTick: 450 * MIN_MULTIPLIER
      }
    ],
    items: [
      {
        itemId: ENVELOPE,
        odds: 1
      },
      {
        itemId: LEVER_ARCH_FILE,
        odds: 1
      },
      {
        itemId: FILING_BOX,
        odds: 1
      }
    ],
    fineRate: 0
  },
  {
    id: "20",
    name: "Todd's Treats",
    description: "Purveyor of fine foods and tonsorial delights",
    street: locations[20].street,
    shortPostcode: locations[20].shortPostcode,
    jobNoLimit: 5,
    added: false,
    addressesFrom: [
      {
        locationId: "t-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "ten-13",
        odds: 1
      },
      {
        locationId: "ten-14",
        odds: 1
      },
      {
        locationId: "ten-15",
        odds: 1
      },
      {
        locationId: "ten-16",
        odds: 1
      },
      {
        locationId: "ten-17",
        odds: 1
      },
      {
        locationId: "ten-18",
        odds: 1
      },
      {
        locationId: "ten-19",
        odds: 1
      },
      {
        locationId: "ten-20",
        odds: 1
      },
      {
        locationId: "ten-21",
        odds: 1
      },
      {
        locationId: "ten-22",
        odds: 1
      },
      {
        locationId: "ten-23",
        odds: 1
      },
      {
        locationId: "ten-24",
        odds: 1
      },
      {
        locationId: "ten-25",
        odds: 1
      },
      {
        locationId: "ten-26",
        odds: 1
      },
      {
        locationId: "ten-27",
        odds: 1
      },
      {
        locationId: "ten-28",
        odds: 1
      },
      {
        locationId: "ten-29",
        odds: 1
      },
      {
        locationId: "ten-30",
        odds: 1
      },
      {
        locationId: "ten-31",
        odds: 1
      },
      {
        locationId: "ten-32",
        odds: 1
      },
      {
        locationId: "ten-33",
        odds: 1
      },
      {
        locationId: "ten-34",
        odds: 1
      },
      {
        locationId: "ten-35",
        odds: 1
      },
      {
        locationId: "ten-36",
        odds: 1
      },
      {
        locationId: "ten-37",
        odds: 1
      },
      {
        locationId: "ten-38",
        odds: 1
      },
      {
        locationId: "ten-39",
        odds: 1
      },
      {
        locationId: "ten-40",
        odds: 1
      },
      {
        locationId: "ten-41",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 20 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 450 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: PIE,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "21",
    name: "Unobtrusive Company Ltd",
    description: "Everyday producer of commonplace things",
    street: locations[21].street,
    shortPostcode: locations[21].shortPostcode,
    jobNoLimit: 5,
    added: false,
    addressesFrom: [
      {
        locationId: "u-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "greater-1",
        odds: 1
      },
      {
        locationId: "greater-2",
        odds: 1
      },
      {
        locationId: "greater-3",
        odds: 1
      },
      {
        locationId: "greater-4",
        odds: 1
      },
      {
        locationId: "greater-5",
        odds: 1
      },
      {
        locationId: "greater-6",
        odds: 1
      },
      {
        locationId: "greater-7",
        odds: 1
      },
      {
        locationId: "greater-8",
        odds: 1
      },
      {
        locationId: "greater-9",
        odds: 1
      },
      {
        locationId: "greater-10",
        odds: 1
      },
      {
        locationId: "greater-11",
        odds: 1
      },
      {
        locationId: "greater-12",
        odds: 1
      },
      {
        locationId: "greater-13",
        odds: 1
      },
      {
        locationId: "greater-14",
        odds: 1
      },
      {
        locationId: "greater-15",
        odds: 1
      },
      {
        locationId: "greater-16",
        odds: 1
      },
      {
        locationId: "greater-17",
        odds: 1
      },
      {
        locationId: "greater-18",
        odds: 1
      },
      {
        locationId: "greater-19",
        odds: 1
      },
      {
        locationId: "greater-20",
        odds: 1
      },
      {
        locationId: "greater-21",
        odds: 1
      },
      {
        locationId: "greater-22",
        odds: 1
      },
      {
        locationId: "greater-23",
        odds: 1
      },
      {
        locationId: "greater-24",
        odds: 1
      },
      {
        locationId: "greater-25",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 71 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
      },
      {
        id: "1",
        type: "scheduled",
        schedule: 67 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
      },
      {
        id: "2",
        type: "scheduled",
        schedule: 57 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
      },
      {
        id: "3",
        type: "scheduled",
        schedule: 47 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
      },
      {
        id: "4",
        type: "scheduled",
        schedule: 37 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
      },
      {
        id: "5",
        type: "scheduled",
        schedule: 29 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: SMALL_BOX,
        odds: 3
      },
      {
        itemId: MEDIUM_BOX,
        odds: 2
      },
      {
        itemId: LARGE_BOX,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "22",
    name: "Vzzzbx TV Rentals",
    description: "Dependable TV rental partnership",
    street: locations[22].street,
    shortPostcode: locations[22].shortPostcode,
    jobNoLimit: 5,
    added: false,
    addressesFrom: [
      {
        locationId: "v-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "greater-21",
        odds: 1
      },
      {
        locationId: "greater-22",
        odds: 1
      },
      {
        locationId: "greater-23",
        odds: 1
      },
      {
        locationId: "greater-24",
        odds: 1
      },
      {
        locationId: "greater-25",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 17 * MIN_MULTIPLIER
      },
    ],
    items: [
      {
        itemId: TV,
        odds: 20
      },
      {
        itemId: SAUSAGE,
        odds: 1
      },
      {
        itemId: SELLOTAPE,
        odds: 1
      },
      {
        itemId: FRIDGE,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "23",
    name: "Wapcaplet and Spang",
    description: "Innovative advertising agency",
    street: locations[23].street,
    shortPostcode: locations[23].shortPostcode,
    jobNoLimit: 5,
    added: false,
    addressesFrom: [
      {
        locationId: "w-company",
        odds: 3
      },
      {
        locationId: "home",
        odds: 2
      },
    ],
    addressesTo: [
      {
        locationId: "ten-21",
        odds: 1
      },
      {
        locationId: "ten-22",
        odds: 1
      },
      {
        locationId: "ten-23",
        odds: 1
      },
      {
        locationId: "ten-24",
        odds: 1
      },
      {
        locationId: "ten-25",
        odds: 1
      },
      {
        locationId: "ten-26",
        odds: 1
      },
      {
        locationId: "ten-27",
        odds: 1
      },
      {
        locationId: "ten-28",
        odds: 1
      },
      {
        locationId: "ten-29",
        odds: 1
      },
      {
        locationId: "ten-30",
        odds: 1
      },
      {
        locationId: "ten-31",
        odds: 1
      },
      {
        locationId: "ten-32",
        odds: 1
      },
      {
        locationId: "ten-33",
        odds: 1
      },
      {
        locationId: "ten-34",
        odds: 1
      },
      {
        locationId: "ten-35",
        odds: 1
      },
      {
        locationId: "ten-36",
        odds: 1
      },
      {
        locationId: "ten-37",
        odds: 1
      },
      {
        locationId: "ten-38",
        odds: 1
      },
      {
        locationId: "ten-39",
        odds: 1
      },
      {
        locationId: "ten-40",
        odds: 1
      },
      {
        locationId: "ten-41",
        odds: 1
      },
      {
        locationId: "ten-42",
        odds: 1
      },
      {
        locationId: "ten-43",
        odds: 1
      },
      {
        locationId: "ten-44",
        odds: 1
      },
      {
        locationId: "ten-45",
        odds: 1
      },
      {
        locationId: "ten-46",
        odds: 1
      },
      {
        locationId: "ten-47",
        odds: 1
      },
      {
        locationId: "ten-48",
        odds: 1
      },
      {
        locationId: "ten-49",
        odds: 1
      },
      {
        locationId: "ten-50",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 71 * MIN_MULTIPLIER
      },
      {
        id: "1",
        type: "scheduled",
        schedule: 68 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 420 * MIN_MULTIPLIER,
        timeLimit: 60 * MIN_MULTIPLIER,
      },
      {
        id: "1",
        type: "random",
        schedule: 43 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 30 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: FLOWERS,
        odds: 10
      },
      {
        itemId: BALLOONS,
        odds: 10
      },
      {
        itemId: BOTTLE_OF_WINE,
        odds: 7
      },
      {
        itemId: BRAND_ENDORSEMENT_CONTRACT,
        odds: 5
      },
      {
        itemId: CASE_OF_WINE,
        odds: 3
      },
      {
        itemId: GREEN_PLANK,
        odds: 1
      },
      {
        itemId: SPIDERS,
        odds: 1
      },
      {
        itemId: CRICKETS,
        odds: 1
      },
      {
        itemId: MICE,
        odds: 1
      },
      {
        itemId: PURPLE_PENS,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "24",
    name: "Xenophilius Press",
    description: "All the latest news from abroad",
    street: locations[24].street,
    shortPostcode: locations[24].shortPostcode,
    jobNoLimit: 6,
    added: false,
    addressesFrom: [
      {
        locationId: "x-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "ten-20",
        odds: 1
      },
      {
        locationId: "ten-21",
        odds: 1
      },
      {
        locationId: "ten-22",
        odds: 1
      },
      {
        locationId: "ten-23",
        odds: 1
      },
      {
        locationId: "ten-24",
        odds: 1
      },
      {
        locationId: "ten-25",
        odds: 1
      },
      {
        locationId: "ten-26",
        odds: 1
      },
      {
        locationId: "ten-27",
        odds: 1
      },
      {
        locationId: "ten-28",
        odds: 1
      },
      {
        locationId: "ten-29",
        odds: 1
      },
      {
        locationId: "ten-30",
        odds: 1
      },
      {
        locationId: "ten-31",
        odds: 1
      },
      {
        locationId: "ten-32",
        odds: 1
      },
      {
        locationId: "ten-33",
        odds: 1
      },
      {
        locationId: "ten-34",
        odds: 1
      },
      {
        locationId: "ten-35",
        odds: 1
      },
      {
        locationId: "ten-36",
        odds: 1
      },
      {
        locationId: "ten-37",
        odds: 1
      },
      {
        locationId: "ten-38",
        odds: 1
      },
      {
        locationId: "ten-39",
        odds: 1
      },
      {
        locationId: "ten-40",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "scheduled",
        schedule: 15 * MIN_MULTIPLIER
      },
    ],
    items: [
      {
        itemId: NEWSPAPER,
        odds: 15
      },
      {
        itemId: HAT,
        odds: 1
      },
      {
        itemId: OWL,
        odds: 1
      },
    ],
    fineRate: 0
  },
  {
    id: "25",
    name: "Yarbles Fashion",
    description: "Brutalist psychedelic fashion",
    street: locations[25].street,
    shortPostcode: locations[25].shortPostcode,
    jobNoLimit: 6,
    added: false,
    addressesFrom: [
      {
        locationId: "y-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "fashion-27",
        odds: 1
      },
      {
        locationId: "fashion-28",
        odds: 1
      },
      {
        locationId: "fashion-29",
        odds: 1
      },
      {
        locationId: "fashion-30",
        odds: 1
      },
      {
        locationId: "fashion-31",
        odds: 1
      },
      {
        locationId: "fashion-32",
        odds: 1
      },
      {
        locationId: "fashion-33",
        odds: 1
      },
      {
        locationId: "fashion-34",
        odds: 1
      },
      {
        locationId: "fashion-35",
        odds: 1
      },
      {
        locationId: "fashion-36",
        odds: 1
      },
      {
        locationId: "fashion-37",
        odds: 1
      },
      {
        locationId: "fashion-38",
        odds: 1
      },
      {
        locationId: "fashion-39",
        odds: 1
      },
      {
        locationId: "fashion-40",
        odds: 1
      },
      {
        locationId: "fashion-41",
        odds: 1
      },
      {
        locationId: "fashion-42",
        odds: 1
      },
      {
        locationId: "fashion-43",
        odds: 1
      },
      {
        locationId: "fashion-44",
        odds: 1
      },
      {
        locationId: "fashion-45",
        odds: 1
      },
      {
        locationId: "fashion-46",
        odds: 1
      },
      {
        locationId: "fashion-47",
        odds: 1
      },
      {
        locationId: "fashion-48",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 13 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 480 * MIN_MULTIPLIER,
        timeLimit: 60 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: HAT,
        odds: 1
      },
      {
        itemId: WALKING_STICK,
        odds: 1
      },
      {
        itemId: EYELINER,
        odds: 1
      },
      {
        itemId: BOOTS,
        odds: 1
      },
    ],
    fineRate: 0.5
  },
  {
    id: "26",
    name: "Ziggurat Hardware",
    description: "Manufacturers of assorted household goods",
    street: locations[26].street,
    shortPostcode: locations[26].shortPostcode,
    jobNoLimit: 8,
    added: false,
    addressesFrom: [
      {
        locationId: "z-company",
        odds: 5
      },
    ],
    addressesTo: [
      {
        locationId: "tech",
        odds: 1
      },
    ],
    jobRules: [
      {
        id: "1",
        type: "random",
        schedule: 10 * MIN_MULTIPLIER,
        startTick: 0 * MIN_MULTIPLIER,
        endTick: 360 * MIN_MULTIPLIER,
        timeLimit: 120 * MIN_MULTIPLIER,
      },
    ],
    items: [
      {
        itemId: INVOICE,
        odds: 1
      },
      {
        itemId: CONTRACT,
        odds: 1,
      },
      {
        itemId: INTEL_4004,
        odds: 1
      },
      {
        itemId: TELSTAR_303,
        odds: 1
      },
      {
        itemId: BT_MERLIN_TONTO,
        odds: 1
      },
      {
        itemId: ANALYTIC_ENGINE,
        odds: 1
      },
      {
        itemId: GNK_POWER_DROID,
        odds: 1
      },
      {
        itemId: ORAC,
        odds: 1
      },
      {
        itemId: MACHINE_PERSON,
        odds: 1
      },
      {
        itemId: T1000000,
        odds: 1
      },
      {
        itemId: AGC,
        odds: 1
      },
    ],
    fineRate: 0
  },
];

const INITIAL_COURIERS: Courier[] = [
  {
    id: "1",
    name: "Agnetha",
    coordinates: [LONG, LAT],
    directions: [],
    added: true,
    coordinateNo: undefined,
    type: BIKE,
    volume: BIKE_VOLUME,
    mass: BIKE_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 14,
    annoyanceLimit: 1000 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "2",
    name: "Bob",
    coordinates: [LONG, LAT],
    directions: [],
    added: true,
    coordinateNo: undefined,
    type: BIKE,
    volume: BIKE_VOLUME,
    mass: BIKE_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 15,
    annoyanceLimit: 100 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 100
  },
  {
    id: "3",
    name: "Clarissa",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE,
    volume: BIKE_VOLUME,
    mass: BIKE_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 13,
    annoyanceLimit: 15 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "4",
    name: "Deirdre",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE,
    volume: BIKE_VOLUME,
    mass: BIKE_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 14,
    annoyanceLimit: 50 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 150
  },
  {
    id: "5",
    name: "Egbert",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE,
    volume: BIKE_VOLUME,
    mass: BIKE_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 13,
    annoyanceLimit: 12 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "6",
    name: "Freida",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE,
    volume: BIKE_VOLUME,
    mass: BIKE_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 16,
    annoyanceLimit: 40 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 200
  },
  {
    id: "7",
    name: "Ginger",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE,
    volume: BIKE_VOLUME,
    mass: BIKE_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 14,
    annoyanceLimit: 9 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "8",
    name: "Hattie",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE,
    volume: BIKE_VOLUME,
    mass: BIKE_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 13.5,
    annoyanceLimit: 30 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 200
  },
  {
    id: "9",
    name: "Icabod",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE_LARGE_TOP,
    volume: BIKE_LARGE_TOP_VOLUME,
    mass: BIKE_LARGE_TOP_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 14.5,
    annoyanceLimit: 8 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "10",
    name: "Jemima",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE_LARGE_TOP,
    volume: BIKE_LARGE_TOP_VOLUME,
    mass: BIKE_LARGE_TOP_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 12,
    annoyanceLimit: 37 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 250
  },
  {
    id: "11",
    name: "Kermit",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE_LARGE_TOP,
    volume: BIKE_LARGE_TOP_VOLUME,
    mass: BIKE_LARGE_TOP_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 13,
    annoyanceLimit: 9 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "12",
    name: "Lol",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE_LARGE_TOP,
    volume: BIKE_LARGE_TOP_VOLUME,
    mass: BIKE_LARGE_TOP_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 16.5,
    annoyanceLimit: 27 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 300
  },
  {
    id: "13",
    name: "Manny",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: BIKE_LARGE_TOP,
    volume: BIKE_LARGE_TOP_VOLUME,
    mass: BIKE_LARGE_TOP_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 11,
    annoyanceLimit: 15 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "14",
    name: "Nobby",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: SMALL_VAN,
    volume: SMALL_VAN_VOLUME,
    mass: SMALL_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 5,
    annoyanceLimit: 47 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 300
  },
  {
    id: "15",
    name: "Om",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: SMALL_VAN,
    volume: SMALL_VAN_VOLUME,
    mass: SMALL_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 4.2,
    annoyanceLimit: 16 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "16",
    name: "Prudence",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: SMALL_VAN,
    volume: SMALL_VAN_VOLUME,
    mass: SMALL_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 5.3,
    annoyanceLimit: 35 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 350
  },
  {
    id: "17",
    name: "Quintum",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: SMALL_VAN,
    volume: SMALL_VAN_VOLUME,
    mass: SMALL_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 4.7,
    annoyanceLimit: 8 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "18",
    name: "Rosetta",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: SMALL_VAN,
    volume: SMALL_VAN_VOLUME,
    mass: SMALL_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 6,
    annoyanceLimit: 30 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 400
  },
  {
    id: "19",
    name: "Stewie",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: MEDIUM_VAN,
    volume: MEDIUM_VAN_VOLUME,
    mass: MEDIUM_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 5,
    annoyanceLimit: 10 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "20",
    name: "Tamora",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: MEDIUM_VAN,
    volume: MEDIUM_VAN_VOLUME,
    mass: MEDIUM_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 5.4,
    annoyanceLimit: 29 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 450
  },
  {
    id: "21",
    name: "Usher",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: MEDIUM_VAN,
    volume: MEDIUM_VAN_VOLUME,
    mass: MEDIUM_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 4.6,
    annoyanceLimit: 9 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "22",
    name: "Vix",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: MEDIUM_VAN,
    volume: MEDIUM_VAN_VOLUME,
    mass: MEDIUM_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 4.9,
    annoyanceLimit: 61 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 500
  },
  {
    id: "23",
    name: "Wanda",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: MEDIUM_VAN,
    volume: MEDIUM_VAN_VOLUME,
    mass: MEDIUM_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 5.3,
    annoyanceLimit: 7 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "24",
    name: "Xander",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: LARGE_VAN,
    volume: LARGE_VAN_VOLUME,
    mass: LARGE_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 4.7,
    annoyanceLimit: 29 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 550
  },
  {
    id: "25",
    name: "Yolanda",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: LARGE_VAN,
    volume: LARGE_VAN_VOLUME,
    mass: LARGE_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 5.1,
    annoyanceLimit: 9 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 0
  },
  {
    id: "26",
    name: "Zaphod",
    coordinates: [LONG, LAT],
    directions: [],
    added: false,
    coordinateNo: undefined,
    type: LARGE_VAN,
    volume: LARGE_VAN_VOLUME,
    mass: LARGE_VAN_MASS,
    jobsIdsInProgress: [],
    mood: 7,
    distanceSoFar: 0,
    speed: 3.9,
    annoyanceLimit: 19 * MIN_MULTIPLIER,
    annoyance: 0,
    wages: 600
  },
];

const INITIAL_JOBS: Job[] = [
  {
    id: "1",
    customerId: "1",
    customerName: INITIAL_CUSTOMERS[0].name,
    from: locations[1],
    to: pr1Location,
    status: UNASSIGNED,
    item: items[0],
    fee: 600,
    cost: 300,
    fine: 0,
    error: undefined,
    timeLimitTick: undefined,
  },
  {
    id: "2",
    customerId: "1",
    customerName: INITIAL_CUSTOMERS[0].name,
    from: locations[1],
    to: pr2Location,
    status: UNASSIGNED,
    item: items[1],
    fee: 800,
    cost: 400,
    fine: 0,
    error: undefined,
    timeLimitTick: undefined,
  },
];

const initialState: GlobalState = {
  couriers: [],
  customers: [],
  jobs: [],
  highlightedCourierId: undefined,
  highlightedJobId: undefined,
  GeoJSONUpdateRequired: false,
  money: 0,
  noJobsCompleted: 0,
  tick: 0,
  gameState: NOT_STARTED,
  jobNo: 1,
  playSounds: true,
  rnd: Math.floor(Math.random() * 100)
};

export { initialState, INITIAL_COURIERS, INITIAL_CUSTOMERS, INITIAL_JOBS };
