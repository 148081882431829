import { useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import HelpOutline from "@material-ui/icons/HelpOutline";

import {
  ASSIGNED,
  IN_PROGRESS,
  UNASSIGNED,
  TOO_BIG,
  getTimeFromTicks
} from "../../../data/constants";

import styles from "./JobRow.module.css";

function JobRow({
  highlightedCourierId,
  job,
  assignJob,
  unassignJob,
  highlightJobId,
  highlightedJobId,
  unUnassignable
}: any) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${job.id}simple-popover` : undefined;

  const assign = (): any => {
    if (job.status === UNASSIGNED) {
      assignJob(job.id, highlightedCourierId);
    }
  };

  const unassign = (): any => {
    if (job.status === ASSIGNED) {
      unassignJob(job.id);
    }
  };

  const highlight = (): any => {
    highlightJobId(job.id);
  };

  const classNames = [];

  if (job.status === ASSIGNED) {
    classNames.push(styles.jobAssigned);
  } else if (job.status === IN_PROGRESS) {
    classNames.push(styles.jobInProgress);
  } else if (job.error === TOO_BIG) {
    classNames.push(styles.jobTooBig);
  } else if (job.timeLimitTick) {
    classNames.push(styles.jobTimeLimit);
  }

  if (highlightedJobId === job.id) {
    classNames.push(styles.jobHoveredOver);
  }

  return (
    <TableRow
      key={job.id}
      className={classNames.join(" ")}
      onMouseEnter={highlight}
    >
      <TableCell size="small">
        <div className={styles.unassignFlexbox}>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Paper className={styles.popoverPadding}>
              <Typography>{job.item.name}</Typography>
              <Typography>{job.item.description}</Typography>
              <Typography>Customer: {job.customerName}</Typography>
              <Typography>{job.from.shortPostcode} - {job.to.shortPostcode}</Typography>
              <Typography>
                From: {job.from.street}
              </Typography>
              <Typography>
                To: {job.to.street}
              </Typography>
              <Typography>Fee: &pound;{(job.fee / 100).toFixed(2)}</Typography>
              <Typography>Cost: &pound;{(job.cost / 100).toFixed(2)}</Typography>
              {job.timeLimitTick && (<Typography>Due by:<span dangerouslySetInnerHTML={{__html: getTimeFromTicks(job.timeLimitTick)}} /></Typography>)}
              {job.timeLimitTick && (<Typography>Fine: &pound;{(job.fine / 100).toFixed(2)}</Typography>)}
            </Paper>
          </Popover>
          <div className={styles.breakpointFont}>{job.id}. {job.from.shortPostcode} - {job.to.shortPostcode}, {job.item.id}&nbsp;</div>
          <div className={styles.unassignFlexbox}>
            {job.status === ASSIGNED && !unUnassignable && <div onClick={unassign} className={styles.pointer}>Unassign</div>}
            {job.status === UNASSIGNED && <div onClick={assign} className={styles.pointer}>Assign</div>}
            <div onClick={handleClick} className={styles.pointer}>
              &nbsp;&nbsp;&nbsp;
              <HelpOutline fontSize="small" />
            </div>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

export default JobRow;
