import { Fragment } from "react";
import { Marker, Popup, GeoJSON } from "react-leaflet";
import * as L from "leaflet";

import {
  PICK_UP,
  DROP_OFF,
  L_MARKER_SHADOW_URL,
  L_ICON_SIZE_SMALL,
  L_ICON_ANCHOR_SMALL,
  L_POPUP_ANCHOR_SMALL,
  L_SHADOW_SIZE_SMALL,
  L_ICON_SIZE_LARGE,
  L_ICON_ANCHOR_LARGE,
  L_POPUP_ANCHOR_LARGE,
  L_SHADOW_SIZE_LARGE,
} from "../data/constants";

const greenIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-green.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_SMALL,
  iconAnchor: L_ICON_ANCHOR_SMALL,
  popupAnchor: L_POPUP_ANCHOR_SMALL,
  shadowSize: L_SHADOW_SIZE_SMALL,
});

const greenBigIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-green.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_LARGE,
  iconAnchor: L_ICON_ANCHOR_LARGE,
  popupAnchor: L_POPUP_ANCHOR_LARGE,
  shadowSize: L_SHADOW_SIZE_LARGE,
});

const violetIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-violet.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_SMALL,
  iconAnchor: L_ICON_ANCHOR_SMALL,
  popupAnchor: L_POPUP_ANCHOR_SMALL,
  shadowSize: L_SHADOW_SIZE_SMALL,
});

const violetBigIcon = new L.Icon({
  iconUrl:
    "/leaflet-color-markers/marker-icon-2x-violet.png",
  shadowUrl: L_MARKER_SHADOW_URL,
  iconSize: L_ICON_SIZE_LARGE,
  iconAnchor: L_ICON_ANCHOR_LARGE,
  popupAnchor: L_POPUP_ANCHOR_LARGE,
  shadowSize: L_SHADOW_SIZE_LARGE,
});

function ScheduleRoute({ jobs, courier, highlightedScheduledJob }: any): any {
  const dirs: any[] = [];

  const dirJobIds: string[] = [];

  courier.directions.forEach((dir: any) => {
    if (dir.GeoJSON) {
      dirs.push(dir);
    }

    dirJobIds.push(dir.jobId);
  });

  const displayJobs = jobs.filter((job: Job) => {
    return dirJobIds.includes(job.id);
  });

  return (
    <Fragment>
      {dirs.map((d: any) => {
        return (
          <GeoJSON
            attribution="Openroute Service"
            data={d.GeoJSON}
            key={d.id}
          />
        );
      })}
      {displayJobs.map((job: Job) => {
        return (
          <Fragment key={`${job.id}fragment`}>
            <Marker
              position={[job.from.coordinates[1], job.from.coordinates[0]]}
              icon={
                highlightedScheduledJob[0] === job.id &&
                highlightedScheduledJob[1] === PICK_UP
                  ? greenBigIcon
                  : greenIcon
              }
              key={`${job.id}from`}
            >
              <Popup>{job.id} pickup</Popup>
            </Marker>
            <Marker
              position={[job.to.coordinates[1], job.to.coordinates[0]]}
              icon={
                highlightedScheduledJob[0] === job.id &&
                highlightedScheduledJob[1] === DROP_OFF
                  ? violetBigIcon
                  : violetIcon
              }
              key={`${job.id}to`}
            >
              <Popup>{job.id} dropoff</Popup>
            </Marker>
          </Fragment>
        );
      })}
    </Fragment>
  );
}

export default ScheduleRoute;
