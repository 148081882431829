import { useState, useEffect, useCallback } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import HelpOutline from "@material-ui/icons/HelpOutline";

import styles from "./CourierRow.module.css";

import { PICK_UP } from "../../../data/constants";

function CourierRow({ highlightCourier, courier, highlightedCourierId }: any) {
  const [anchorEl, setAnchorEl] = useState(null);

  const keyFunction = useCallback((event) => {
    const key = event.key.toLowerCase();

    if (courier.added && courier.name.charAt(0).toLowerCase() == key) {
      highlightCourier(courier.id);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyFunction, false);

    return () => {
      document.removeEventListener("keydown", keyFunction, false);
    };
  }, [keyFunction]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `${courier.id}simple-popover` : undefined;

  const highlight = (): any => {
    highlightCourier(courier.id);
  };

  let classNames = [];

  if (highlightedCourierId === courier.id) {
    classNames.push(styles.courierHighlighted);
  }

  if (courier.directions.length) {
    let noPickUps = 0;
    let noDropOffs = 0;

    courier.directions.forEach((dir: any) => {
      if (dir.pickUpOrDropOff === PICK_UP) {
        noPickUps++;
      } else {
        noDropOffs++;
      }
    });

    if (noDropOffs > noPickUps) {
      // Job in progress
      classNames.push(styles.courierInProgress);
    } else {
      classNames.push(styles.courierAssigned);
    }
  }

  let moodEmoji = "😠";

  if (courier.mood >= 8) {
    moodEmoji = "😁";
  } else if (courier.mood >= 6) {
    moodEmoji = "😀";
  } else if (courier.mood >= 4) {
    moodEmoji = "😐";
  } else if (courier.mood >= 2) {
    moodEmoji = "😒";
  }

  return (
    <TableRow
      key={courier.id}
      className={classNames.join(" ")}
      onMouseEnter={highlight}
      onClick={highlight}
    >
      <TableCell size="small">
        <div className={styles.moodFlexbox}>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Paper className={styles.popoverPadding}>
              <Typography>Name: {courier.name}</Typography>
              <Typography>Vehicle: {courier.type}</Typography>
              <Typography>
                Max volume: {Number(courier.volume).toLocaleString()} l
              </Typography>
              <Typography>
                Max weight: {Number(courier.mass).toLocaleString()} kg
              </Typography>
            </Paper>
          </Popover>
          <div className={styles.moodFlexbox}>
            <div className={styles.breakpointFont}>{courier.name} ({courier.type})</div>
            <div className={styles.emojiHelp}>
              <div className={styles.breakpointFont}>{moodEmoji}</div>
              <div onClick={handleClick} className={styles.pointer}>
                <HelpOutline fontSize="small" />
              </div>
            </div>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

// <div>{courier.mood}</div>

export default CourierRow;
