import { useContext, Fragment } from "react";
import { Typography } from "@material-ui/core";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ScheduleDraggableBox from "../ScheduleDraggableBox";

import styles from "./Schedule.module.css";

import { GlobalContext } from "../../context/reducers/globalReducer";

import { actionTypes } from "../../context/actions/globalActions";

import { PICK_UP } from "../../data/constants";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  scheduleFont: {
    [theme.breakpoints.up(992)]: {
      'font-size': '1rem'
    },
    [theme.breakpoints.down(992)]: {
      'font-size': '0.8rem'
    }
  },
}));

function Schedule({ highlightedCourierId, highlightScheduledJob }: any) {
  const { state, dispatchToGlobal } = useContext(GlobalContext);
  const { couriers, jobs } = state;

  const classes = useStyles();

  let highlightedCourier: Courier | undefined;
  const scheduledPoints: any[] = [];

  if (highlightedCourierId) {
    highlightedCourier = couriers.find((courier: Courier) => {
      return courier.id === highlightedCourierId;
    });

    if (highlightedCourier) {
      const customersById: any = {};

      jobs.forEach((job: Job) => {
        customersById[job.id] = `${job.from.shortPostcode} - ${job.to.shortPostcode}`;
      });

      highlightedCourier.directions.forEach((dir: any) => {
        let podo = "Dropoff";

        if (dir.pickUpOrDropOff === PICK_UP) {
          podo = "Pickup";
        }

        scheduledPoints.push({
          id: dir.id,
          pickUpOrDropOff: dir.pickUpOrDropOff,
          jobId: dir.jobId,
          content: `${dir.jobId} ${customersById[dir.jobId]} ${podo}`,
        });
      });
    }
  }

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    if (result.destination.index === 0) {
      // Can't change the location they're currently heading to as it'll mess up the waypoint caching
      return;
    }

    dispatchToGlobal({
      type: actionTypes.REORDER_DIRECTIONS,
      courierId: highlightedCourierId,
      sourceDirectionId: result.draggableId,
      sourceDirectionIndex: result.source.index,
      destinationDirectionIndex: result.destination.index,
    });
  }

  return (
    <div className={styles.schedule}>
      {highlightedCourier && (
        <Fragment>
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className={styles.scheduleDroppable}
                    {...provided.droppableProps}
                  >
                    {scheduledPoints.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <ScheduleDraggableBox
                            provided={provided}
                            item={item}
                            highlightScheduledJob={highlightScheduledJob}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {!scheduledPoints.length && (
              <div className={styles.schedulePoint}>
                <Typography className={classes.scheduleFont}>
                  No jobs scheduled for {highlightedCourier.name}
                </Typography>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default Schedule;
