import { useContext } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import JobRow from "./rows/JobRow";

import styles from "./Jobs.module.css";

import { GlobalContext } from "../../context/reducers/globalReducer";

import { UNASSIGNED } from "../../data/constants";

function Jobs({
  highlightJobId,
  highlightedJobId,
  highlightedCourierId,
  assignJob,
  unassignJob,
  status,
}: any) {
  const { state } = useContext(GlobalContext);
  let { jobs, couriers } = state;

  if (status) {
    if (status === UNASSIGNED) {
      jobs = jobs.filter((job: Job) => job.status === UNASSIGNED);
    } else if (status === "assigned") {
      jobs = jobs.filter((job: Job) => job.status !== UNASSIGNED);
    }
  }

  const unUnassignableJobs: any = {}

  if (!status || status == "assigned") {
    // Find out which jobs are un unassignable due to being first in a courier's list

    // My word this is slow
    jobs.forEach(job => {
      couriers.forEach(courier => {
        if (courier.directions.length && courier.directions[0].jobId === job.id) {
          unUnassignableJobs[job.id] = true;
        }
      })
    })
  }
 
  return (
    <div className={styles.jobs}>
      <Table>
        <TableBody>
          {!jobs.length && status === "all" && (
            <TableRow>
              <TableCell size="small">
                Click 'Customers' to get more jobs
              </TableCell>
            </TableRow>
          )}
          {jobs.map((job: Job) => (
            <JobRow
              key={job.id}
              job={job}
              highlightedCourierId={highlightedCourierId}
              assignJob={assignJob}
              unassignJob={unassignJob}
              highlightJobId={highlightJobId}
              highlightedJobId={highlightedJobId}
              unUnassignable={unUnassignableJobs[job.id]}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default Jobs;
