export const BELT = "Belt";
export const SUIT = "Suit";
export const CLOTHES_RACK = "Clothes rack";
export const PERFUME = "Perfume";
export const SHOES = "Shoes";
export const ENVELOPE = "Envelope";
export const LEVER_ARCH_FILE = "Lever arch file";
export const FILING_BOX = "Filing box";
export const TEN_FILING_BOXES = "Ten filing boxes";
export const TWENTY_FILING_BOXES = "Twenty filing boxes";

// Opera
export const MUSIC_SCORE = "Music score";
export const OPERA_COSTUME = "Costume";
export const OPERA_SHOES = "Opera shoes";
export const OPERA_GLASSES = "Opera glasses";
export const OPERA_MASK = "Opera mask";
export const CONDUCTOR_BATON = "Conductor baton";
export const SUPERTITLE_SCREEN = "Supertitle screen";
export const OPERA_SCENERY = "Scenery";
export const SPOTLIGHT = "Spotlight";
export const STAGE_WEIGHT = "Stage weight";
export const STAGE_CURTAINS = "Stage curtains";

// Zeus
export const INVOICE = "Invoice";
export const CONTRACT = "Contract";
export const INTEL_4004 = "Intel 4004";
export const TELSTAR_303 = "Telstar";
export const BT_MERLIN_TONTO = "BT Merlin Tonto";
export const ANALYTIC_ENGINE = "Analytic engine";
export const GNK_POWER_DROID = "GNK Power Droid";
export const ORAC = "Orac";
export const MACHINE_PERSON = "Machine person";
export const T1000000 = "T1000000";
export const AGC = "Apollo Guidance Computer";

// Toys
export const BRAND_ENDORSEMENT_CONTRACT = "Brand endorsement contract";
export const LEGAL_FORMS = "Legal forms";
export const PHONE = "Phone";
export const SPACE_PROBE = "Space probe";
export const CALCULATOR = "Calculator";
export const CEREAL = "Cereal";
export const RELIGIOUS_BISCUITS = "Religious biscuits";
export const MONKEY_BITES = "Monkey bites";

// Advertising
export const BALLOONS = "Balloons";
export const FLOWERS = "Flowers";
export const BOTTLE_OF_WINE = "Bottle of wine";
export const CASE_OF_WINE = "Case of wine";
export const GREEN_PLANK = "Green plank of wood";
export const SPIDERS = "Spiders";
export const CRICKETS = "Crickets";
export const MICE = "Mice";
export const PURPLE_PENS = "Purple felt pens";
export const HAMBURGERS = "Bucket of hamburgers";
export const SPACE_INVADERS = "Space invader cabinet";

// Film company
export const FILM_REEL = "Film reel";
export const ELEPHANT_STATUE = "Elephant statue";
export const STUFFED_ANIMAL = "Stuffed animal";
export const SUBMARINE = "Submarine";
export const ORANGES = "Oranges";
export const FLAMINGOES = "Flamingoes";
export const PRUSSIAN_WAISTCOAT = "Prussian Lobster Waistcoat";
export const DIRECTORS_CHAIR = "Director's chair";
export const SPACESHIP = "Spaceship";
export const FILM_CAMERA = "Film camera";

// Estate agents
export const KEYS = "Keys";
export const SECTION_21 = "Section 21 notice";

export const LARGE_HEAVY_BROWN_ENVELOPE = "Large heavy brown envelope";
export const CASE_OF_CHAMPAGNE = "Case of champagne";
export const HAMPER = "Hamper"
export const FABERGE_EGG = "Faberge Egg";

export const COLA = "Can of cola"
export const PLACARD = "Placard"
export const P45 = "P45"

export const CHEQUE = "Cheque";

export const PITCH_DECK = "Pitch deck";

// Fashion
export const HAT = "Hat"
export const COAT = "Coat"
export const GLOVES = "Gloves"

export const CIGARS = "Cigars"

export const SMALL_BOX = "Small box"
export const MEDIUM_BOX = "Medium box"
export const LARGE_BOX = "Large box"

export const TV = "TV";
export const SAUSAGE = "A sausage";
export const SELLOTAPE = "Sellotape";
export const FRIDGE = "Fridge"

export const NEWSPAPER = "Newspaper";
export const OWL = "Owl";

export const SANDWICH = "Sandwich";

export const PIE = "Pie";

export const BOOTS = "Boots";
export const WALKING_STICK = "Walking stick";
export const EYELINER = "Eyeliner";

export const items = [
  {
    id: BELT,
    volume: 5,
    mass: 5,
    description:
      "A fashionable belt.",
  },
  {
    id: SUIT,
    volume: 30,
    mass: 10,
    description:
      "A fashionable suit.",
  },
  {
    id: CLOTHES_RACK,
    volume: 1000,
    mass: 300,
    description:
      "A large rack full of clothes.",
  },
  {
    id: PERFUME,
    volume: 5,
    mass: 5,
    description:
      "An bottle of perfume.",
  },
  {
    id: SHOES,
    volume: 10,
    mass: 5,
    description:
      "A pair of shoes in a shoebox",
  },
  {
    id: ENVELOPE,
    volume: 1,
    mass: 1,
    description:
      "An A4 envelope, usually for a document.",
  },
  {
    id: LEVER_ARCH_FILE,
    volume: 10,
    mass: 10,
    description:
      "A folder of up to 500 pages.",
  },
  {
    id: FILING_BOX,
    volume: 30,
    mass: 30,
    description:
      "A large box of documents.",
  },
  {
    id: TEN_FILING_BOXES,
    volume: 300,
    mass: 300,
    description:
      "Ten boxes of documents.",
  },
  {
    id: TWENTY_FILING_BOXES,
    volume: 600,
    mass: 600,
    description:
      "Twenty boxes of documents.",
  },
  {
    id: MUSIC_SCORE,
    volume: 1,
    mass: 1,
    description: "Music score",
  },
  {
    id: OPERA_COSTUME,
    volume: 50,
    mass: 20,
    description: "Opera costume",
  },
  {
    id: OPERA_SHOES,
    volume: 20,
    mass: 2,
    description: "Opera shoes",
  },
  {
    id: OPERA_GLASSES,
    volume: 5,
    mass: 1,
    description: "Opera glasses",
  },
  {
    id: OPERA_MASK,
    volume: 5,
    mass: 1,
    description: "Decorative mask",
  },
  {
    id: CONDUCTOR_BATON,
    volume: 1,
    mass: 1,
    description: "Conductor's baton",
  },
  {
    id: SUPERTITLE_SCREEN,
    volume: 1000,
    mass: 100,
    description: "A supertitle screen",
  },
  {
    id: OPERA_SCENERY,
    volume: 5000,
    mass: 300,
    description: "Opera scenery",
  },
  {
    id: SPOTLIGHT,
    volume: 200,
    mass: 50,
    description: "A stage spotlight",
  },
  {
    id: STAGE_WEIGHT,
    volume: 50,
    mass: 100,
    description: "A stage weight",
  },
  {
    id: STAGE_CURTAINS,
    volume: 2000,
    mass: 500,
    description: "Stage curtains",
  },
  {
    id: INVOICE,
    volume: 1,
    mass: 1,
    description: "An invoice",
  },
  {
    id: CONTRACT,
    volume: 1,
    mass: 1,
    description: "A contract",
  },
  {
    id: INTEL_4004,
    volume: 1,
    mass: 1,
    description: "Homebrew computing",
  },
  {
    id: TELSTAR_303,
    volume: 1000,
    mass: 653,
    description: "Communications device",
  },
  {
    id: BT_MERLIN_TONTO,
    volume: 50,
    mass: 10,
    description: "Modern computer",
  },
  {
    id: ANALYTIC_ENGINE,
    volume: 20000,
    mass: 3000,
    description: "Basic computer",
  },
  {
    id: GNK_POWER_DROID,
    volume: 500,
    mass: 100,
    description: "Charger",
  },
  {
    id: ORAC,
    volume: 100,
    mass: 10,
    description: "Chatbot",
  },
  {
    id: MACHINE_PERSON,
    volume: 100,
    mass: 10,
    description: "Robot companion",
  },
  {
    id: T1000000,
    volume: 20000,
    mass: 3000,
    description: "Home security",
  },
  {
    id: AGC,
    volume: 32,
    mass: 33,
    description: "Early satnav",
  },
  {
    id: BRAND_ENDORSEMENT_CONTRACT,
    volume: 1,
    mass: 1,
    description: "Contract",
  },
  {
    id: LEGAL_FORMS,
    volume: 5,
    mass: 5,
    description: "Legal forms",
  },
  {
    id: PHONE,
    volume: 1,
    mass: 1,
    description: "Phone",
  },
  {
    id: SPACE_PROBE,
    volume: 500,
    mass: 500,
    description: "Space probe",
  },
  {
    id: CALCULATOR,
    volume: 1,
    mass: 1,
    description: "Calculator",
  },
  {
    id: CEREAL,
    volume: 2,
    mass: 1,
    description: "box of cereal",
  },
  {
    id: RELIGIOUS_BISCUITS,
    volume: 1,
    mass: 1,
    description: "Holy comestibles",
  },
  {
    id: MONKEY_BITES,
    volume: 1,
    mass: 1,
    description: "Tasty snacks",
  },
  {
    id: BALLOONS,
    volume: 200,
    mass: 1,
    description: "Bunch of balloons",
  },
  {
    id: FLOWERS,
    volume: 20,
    mass: 3,
    description: "Bunch of flowers",
  },
  {
    id: BOTTLE_OF_WINE,
    volume: 1,
    mass: 1,
    description: "Bottle of wine",
  },
  {
    id: CASE_OF_WINE,
    volume: 6,
    mass: 6,
    description: "Case of wine",
  },
  {
    id: GREEN_PLANK ,
    volume: 5,
    mass: 2,
    description: "A green plank of wood",
  },
  {
    id: SPIDERS,
    volume: 10,
    mass: 2,
    description: "Lots of spiders",
  },
  {
    id: CRICKETS,
    volume: 10,
    mass: 2,
    description: "Lots of crickets",
  },
  {
    id: MICE,
    volume: 10,
    mass: 2,
    description: "Lots of mice",
  },
  {
    id: PURPLE_PENS,
    volume: 10,
    mass: 10,
    description: "All the purple felt pens",
  },
  {
    id: HAMBURGERS,
    volume: 10,
    mass: 10,
    description: "Sack of hamburgers",
  },
  {
    id: SPACE_INVADERS,
    volume: 1000,
    mass: 100,
    description: "Space invaders arcade cabinet",
  },  
  {
    id: FILM_REEL,
    volume: 10,
    mass: 2,
    description: "A film reel",
  },
  {
    id: ELEPHANT_STATUE,
    volume: 100,
    mass: 50,
    description: "An elephant statue",
  },
  {
    id: STUFFED_ANIMAL,
    volume: 400,
    mass: 100,
    description: "A stuffed animal",
  },
  {
    id: SUBMARINE,
    volume: 2000,
    mass: 200,
    description: "A submarine prop",
  },
  {
    id: ORANGES,
    volume: 5000,
    mass: 3000,
    description: "30,0000 oranges",
  },
  {
    id: FLAMINGOES,
    volume: 100,
    mass: 10,
    description: "Two plastic flamingoes",
  },
  {
    id: PRUSSIAN_WAISTCOAT,
    volume: 1,
    mass: 1,
    description: "A prussian lobster's waistcoat",
  },
  {
    id: DIRECTORS_CHAIR,
    volume: 20,
    mass: 5,
    description: "Director's chair",
  },
  {
    id: SPACESHIP,
    volume: 500,
    mass: 50,
    description: "Spaceship prop",
  },
  {
    id: FILM_CAMERA,
    volume: 50,
    mass: 20,
    description: "A film camera",
  },  
  {
    id: KEYS,
    volume: 1,
    mass: 1,
    description: "House keys",
  },  
  {
    id: SECTION_21,
    volume: 1,
    mass: 1,
    description: "Section 21 notice",
  },  
  {
    id: LARGE_HEAVY_BROWN_ENVELOPE,
    volume: 2,
    mass: 2,
    description: "Large heavy brown envelope",
  },  
  {
    id: CASE_OF_CHAMPAGNE,
    volume: 30,
    mass: 10,
    description: "Lots of Dom",
  },
  {
    id: HAMPER,
    volume: 50,
    mass: 10,
    description: "A luxury hamper",
  },
  {
    id: FABERGE_EGG,
    volume: 5,
    mass: 5,
    description: "A Faberge egg",
  },
  {
    id: COLA,
    volume: 1,
    mass: 1,
    description: "Refreshing can of cola",
  },
  {
    id: PLACARD,
    volume: 5,
    mass: 1,
    description: "Inspiring message",
  },
  {
    id: P45,
    volume: 5,
    mass: 1,
    description: "Administrative form",
  },
  {
    id: CHEQUE,
    volume: 1,
    mass: 1,
    description: "Cheque",
  },
  {
    id: PITCH_DECK,
    volume: 10,
    mass: 10,
    description: "Pitch deck",
  },
  {
    id: HAT,
    volume: 40,
    mass: 2,
    description: "Hat",
  },
  {
    id: COAT,
    volume: 20,
    mass: 5,
    description: "Coat",
  },
  {
    id: GLOVES,
    volume: 1,
    mass: 1,
    description: "Gloves",
  },
  {
    id: CIGARS,
    volume: 1,
    mass: 1,
    description: "Box of fine cigars",
  },
  {
    id: SMALL_BOX,
    volume: 10,
    mass: 10,
    description: "Small plain box",
  },
  {
    id: MEDIUM_BOX,
    volume: 30,
    mass: 30,
    description: "Medium plain box",
  },
  {
    id: LARGE_BOX,
    volume: 50,
    mass: 50,
    description: "Large plain box",
  },
  {
    id: TV,
    volume: 100,
    mass: 30,
    description: "A big telly",
  },
  {
    id: SAUSAGE,
    volume: 1,
    mass: 1,
    description: "A sausage",
  },
  {
    id: SELLOTAPE,
    volume: 1,
    mass: 1,
    description: "A bit of sellotape",
  },
  {
    id: FRIDGE,
    volume: 200,
    mass: 50,
    description: "A fridge",
  },
  {
    id: NEWSPAPER,
    volume: 10,
    mass: 10,
    description: "A newspaper",
  },
  {
    id: OWL,
    volume: 50,
    mass: 2,
    description: "An owl",
  },
  {
    id: SANDWICH,
    volume: 1,
    mass: 1,
    description: "Crusty marmalade doorstep",
  },
  {
    id: PIE,
    volume: 1,
    mass: 1,
    description: "Freshly made pie",
  },
  {
    id: WALKING_STICK,
    volume: 1,
    mass: 1,
    description: "A walking stick",
  },
  {
    id: BOOTS,
    volume: 2,
    mass: 2,
    description: "A pair of boots",
  },
  {
    id: EYELINER,
    volume: 1,
    mass: 1,
    description: "Eyeliner",
  },
];