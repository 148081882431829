import { useContext, useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import CourierPage from "./CourierDetail";
import CourierPageRow from "./rows/CouriersPageRow";

import { actionTypes } from "../../context/actions/globalActions";

import styles from "./CouriersPage.module.css";

import { GlobalContext } from "../../context/reducers/globalReducer";
import { Typography } from "@material-ui/core";

function CouriersPage() {
  const { state, dispatchToGlobal } = useContext(GlobalContext);
  const { couriers } = state;

  const [highlightedCourier, setHighlightedCourier] = useState<Courier>();

  const highlightCourier = (courierId: string) => {
    const courier = couriers.find((courier: Courier) => {
      return courier.id === courierId;
    });

    if (courier) {
      setHighlightedCourier(courier);
    }
  };

  const addCourier = (courierId: string): any => {
    dispatchToGlobal({ type: actionTypes.ADD_COURIER, courierId });
  };

  return (
    <div className={styles.couriersPage}>
      <div className={styles.couriersPageRows}>
        <Table>
          <TableBody>
            {couriers.map((courier: any) => (
              <CourierPageRow
                key={courier.id}
                courier={courier}
                addCourier={addCourier}
                highlightCourier={highlightCourier}
                highlightedCourier={highlightedCourier}
              />
            ))}
          </TableBody>
        </Table>
        {!couriers.length && (<div className={styles.startGame}><Typography>Start a game to see the couriers.</Typography></div>)}
      </div>
      <div className={styles.couriersPagePage}>
        {highlightedCourier && (
          <CourierPage highlightedCourier={highlightedCourier} />
        )}
      </div>
    </div>
  );
}

export default CouriersPage;
