import { useContext, Fragment } from "react";
import CourierMarker from "./CourierMarker";

import { GlobalContext } from "../context/reducers/globalReducer";

function CourierMarkers({ highlightedCourierId, highlightCourier, isPlaying }: any) {
  const foo = useContext(GlobalContext);
  const couriers = foo.state.couriers;

  const addedCouriers = couriers.filter((courier: any) => {
    return courier.added;
  });

  return (
    <Fragment>
      {addedCouriers.map((courier: Courier) => {
        return (
          <CourierMarker
            courier={courier}
            highlightedCourierId={highlightedCourierId}
            highlightCourier={highlightCourier}
            key={courier.id}
            isPlaying={isPlaying}
          />
        );
      })}
    </Fragment>
  );
}

export default CourierMarkers;
