/* eslint-disable no-undefined */
import { createContext } from "react";
import { Dispatch } from "react";

import { nanoid } from "nanoid";

import { actionTypes } from "../actions/globalActions";

import {
  initialState,
  locations,
  INITIAL_COURIERS,
  INITIAL_CUSTOMERS,
  INITIAL_JOBS,
} from "../initialState";

import {
  ASSIGNED,
  IN_PROGRESS,
  PICK_UP,
  DROP_OFF,
  UNASSIGNED,
  TOO_BIG,
  MAX_TICKS,
  NOT_STARTED,
  PLAYING,
  PAUSED,
  GAME_OVER,
  MIN_MULTIPLIER,
  LARGE_CUSTOMER_JOB_NO_LIMIT,
  MEDIUM_CUSTOMER_JOB_NO_LIMIT
} from "../../data/constants";

import { items } from "../../data/items";

// The Provider value has to be of the same type as sent to createContext.
//  but useReducer doesn't work outside a react function
const pointlessDispatch: Dispatch<any> = () => {};

export const GlobalContext = createContext({
  state: initialState,
  dispatchToGlobal: pointlessDispatch,
});

export const profit = (
  jobId: string,
  jobs: Job[],
  tick: number
): number => {
  let profit = 0;

  const job = jobs.find((j: Job) => j.id === jobId);

  if (job) {
    profit = job.fee - job.cost;

    if (job.timeLimitTick && job.timeLimitTick < tick) {
      profit -= job.fine;
    }
  }

  return profit;
};

const chooseRandomObject = (objects: any, ids: any) => {
  const maxOdds = objects.reduce((total: any, item: any) => {
    return total + item.odds;
  }, 0);

  const randomOdds = Math.floor(Math.random() * maxOdds);

  let oddsSoFar = 0;

  const oddsItem = objects.find((item: any) => {
    oddsSoFar += item.odds;

    if (oddsSoFar > randomOdds) {
      return true;
    }

    return false;
  });

  // @todo - there shouldn't be any need for this
  const definedOddsItem = oddsItem
    ? oddsItem
    : objects[objects.length - 1];

  // Slow, hacky, generally crap
  // @todo - change locations and items to hash not array?
  const randomItem = ids.find(
    (item: Item) => item.id === definedOddsItem.itemId || item.id === definedOddsItem.locationId
  );

  if (!randomItem) {
    console.log("________ RANDOM ITEM NOT GENERATED!!!");
    console.log(`____ maxOdds ${maxOdds}`);
    console.log(`____ maxOdds ${randomOdds}`);
    console.log(`____ oddsSoFar ${oddsSoFar}`);
    console.log(oddsItem);
    console.log(definedOddsItem);

    return ids[ids.length - 1];
  }

  return randomItem;
}

export const createJob = (customer: Customer, locations: any, jobNo: number, jobRule: any, tick: number): Job => {
  const fromLocation: RDLocation = chooseRandomObject(customer.addressesFrom, locations);
  const toLocation: RDLocation = chooseRandomObject(customer.addressesTo, locations);

  const fromCoordinates = fromLocation.coordinates;
  const toCoordinates = toLocation.coordinates;

  const ns = Number(fromCoordinates[0]) * 10000 - Number(toCoordinates[0]) * 10000;
  const ew = Number(fromCoordinates[1]) * 10000 - Number(toCoordinates[1]) * 10000;

  const distance = Math.sqrt(ns * ns + ew * ew);

  let fee = 600 + (Math.floor(distance / 100) * 100);
  let cost = Math.floor(fee / 2);

  const randomItem: Item = chooseRandomObject(customer.items, items);

  // Reduce fee by client size - large clients are loss leaders
  if (Number(customer.jobNoLimit) >= LARGE_CUSTOMER_JOB_NO_LIMIT) {

    // Charge far more for large items
    if (randomItem.volume >= 1000) {
      cost *= 2;
      fee *= 4;
    } else if (randomItem.volume < 500) {
      fee = Math.floor(fee * .4);
    }
  } else if (Number(customer.jobNoLimit) >= MEDIUM_CUSTOMER_JOB_NO_LIMIT) {
    fee = Math.floor(fee * .8);
  }

  let timeLimitTick;
  let fine = 0;

  if (jobRule.timeLimit) {
    timeLimitTick = tick + jobRule.timeLimit;

    fine = Math.floor(fee * customer.fineRate);
  }

  const newJob = {
    id: jobNo.toString(),
    customerId: customer.id,
    customerName: customer.name,
    name: customer.name,
    from: fromLocation,
    to: toLocation,
    status: UNASSIGNED,
    item: randomItem,
    fee,
    cost,
    fine,
    error: undefined,
    timeLimitTick
  };

  return newJob;
};

export const swapLocations = (
  cNo: number,
  coordinates: any[],
  directions: any[],
  sourceDirectionIndex: number,
  destinationDirectionIndex: number
): number | undefined => {
  // The requested move is permissible. Figure out how to change
  //  the 'to' and 'from' values of any directions concerned.
  let coordinateNo: number | undefined = cNo;

  const source = directions[sourceDirectionIndex];
  const destination = directions[destinationDirectionIndex];

  if (sourceDirectionIndex < destinationDirectionIndex) {
    // If the source index is less than the destination, the destination moves
    //  down.
    source.from = destination.to;
    source.GeoJSON = undefined;
    source.GeoJSONRequested = false;

    if (sourceDirectionIndex === 0) {
      directions[1].from = coordinates;
      directions[1].GeoJSON = undefined;
      directions[1].GeoJSONRequested = false;

      coordinateNo = undefined;
    } else {
      // Can't be the maximum index as source index is the lower
      directions[sourceDirectionIndex + 1].from =
        directions[sourceDirectionIndex - 1].to;
      directions[sourceDirectionIndex + 1].GeoJSON = undefined;
      directions[sourceDirectionIndex + 1].GeoJSONRequested = false;
    }

    if (destinationDirectionIndex + 1 < directions.length) {
      directions[destinationDirectionIndex + 1].from = source.to;
      directions[destinationDirectionIndex + 1].GeoJSON = undefined;
      directions[destinationDirectionIndex + 1].GeoJSONRequested = false;
    }
  } else {
    // If the source index is greater than the destination, the destination moves
    //  up.
    destination.from = source.to;
    destination.GeoJSON = undefined;
    destination.GeoJSONRequested = false;

    if (destinationDirectionIndex === 0) {
      source.from = coordinates;

      coordinateNo = undefined;
    } else {
      source.from = directions[destinationDirectionIndex - 1].to;
    }

    source.GeoJSON = undefined;
    source.GeoJSONRequested = false;

    if (sourceDirectionIndex + 1 < directions.length) {
      directions[sourceDirectionIndex + 1].from =
        directions[sourceDirectionIndex - 1].to;
      directions[sourceDirectionIndex + 1].GeoJSON = undefined;
      directions[sourceDirectionIndex + 1].GeoJSONRequested = false;
    }
  }

  return coordinateNo;
};

export const unassignJobFromCourier = (
  courier: Courier,
  jobId: string
): [Courier, boolean] => {
  if (courier.directions.length === 2) {
    // There's only one job in the queue
    // @todo - should be able to get rid of this
    return [
      {
        ...courier,
        directions: [],
        coordinateNo: undefined,
      },
      false,
    ];
  } else {
    let updateFrom: any | undefined;

    const newDirections: Direction[] = [];

    let newCourierLocation = courier.coordinates;
    let newCoordinateNo = courier.coordinateNo;

    courier.directions.forEach((dir, i): void => {
      let newDir: Direction;

      if (updateFrom && dir.jobId !== jobId) {
        // Previously the dir was for this job. This one isn't, so
        //  store the previous (removed) dir's 'from' here
        newDir = {
          ...dir,
          from: updateFrom,
          GeoJSON: undefined,
          GeoJSONRequested: false,
        };

        updateFrom = undefined;
      } else {
        newDir = { ...dir };
      }

      if (dir.jobId === jobId) {
        // It's one of the two entries in directions for this job
        if (!updateFrom) {
          updateFrom = dir.from;
        }
      } else {
        newDirections.push(newDir);
      }
    });

    const newCourier = {
      ...courier,
      directions: newDirections,
      coordinates: newCourierLocation,
      coordinateNo: newCoordinateNo,
    };

    return [newCourier, true];
  }
};



export const unassignOverweightJobFromCourier = (
  courier: Courier,
  jobId: string
): [Courier, boolean] => {
  if (courier.directions.length === 2) {
    // There's only one job in the queue
    // @todo - should be able to get rid of this
    return [
      {
        ...courier,
        directions: [],
        coordinateNo: undefined,
      },
      false,
    ];
  } else {
    let updateFrom: any | undefined;

    // It's necessaryily the first item in directions
    const firstTo = courier.directions[0].to;
    
    const trimmedDirections = [...courier.directions];

    trimmedDirections.shift();

    trimmedDirections[0] = {
      ...trimmedDirections[0],
      from: firstTo,
      GeoJSON: undefined,
      GeoJSONRequested: false
    };

    const newDirections: Direction[] = [];

    trimmedDirections.forEach((dir, i): void => {
      let newDir: Direction;

      if (updateFrom && dir.jobId !== jobId) {
        // Previously the dir was for this job. This one isn't, so
        //  store the previous (removed) dir's 'from' here
        newDir = {
          ...dir,
          from: updateFrom,
          GeoJSON: undefined,
          GeoJSONRequested: false,
        };

        updateFrom = undefined;
      } else {
        newDir = { ...dir };
      }

      if (dir.jobId === jobId) {
        // It's one of the two entries in directions for this job
        if (!updateFrom) {
          updateFrom = dir.from;
        }
      } else {
        newDirections.push(newDir);
      }
    });

    const newCourier = {
      ...courier,
      directions: newDirections
    };

    return [newCourier, true];
  }
};




const ping = new Audio('../../sounds/ping.mp3');
const kaching = new Audio('../../sounds/ka-ching.mp3');
const fail = new Audio('../../sounds/fail.mp3');
const bell = new Audio('../../sounds/bell.mp3');

const endGame = (state: GlobalState, tick: number): GlobalState => {
  let money = state.money;

  // Deduct fines for all jobs with fines, regardless of whether they're assigned, as they
  //  necessarily haven't been completed.
  state.jobs.forEach((job: Job)=> {
    if (job.timeLimitTick) {
      money -= job.fine;
    }
  })

  // We don't add fees for any incomplete jobs. If we did all non time limited jobs could be left unassigned
  //  until the end of the day, or assigned at the last minute if we only added fees for assigned ones, and
  //  the player would get lots of fees for having done nothing

  return {
    ...state,
    tick,
    money,
    gameState: GAME_OVER,
  };
}

const moodChange = (mood: number, change: number) => {
  mood += change;

  if (mood > 10) {
    return 10;
  }

  if (mood < 0) {
    return 0;
  }

  return mood;
}

export const globalReducer = (state: any, action: Action): GlobalState => {
  Object.freeze(state);

  let newCouriers;
  let newJobs: Job[];
  let GeoJSONUpdateRequired;

  switch (action.type) {
    case actionTypes.START:
      return {
        couriers: INITIAL_COURIERS,
        customers: INITIAL_CUSTOMERS,
        jobs: INITIAL_JOBS,
        highlightedCourierId: "1",
        highlightedJobId: "1",
        GeoJSONUpdateRequired: false,
        money: 0,
        noJobsCompleted: 0,
        tick: 0,
        gameState: PLAYING,
        jobNo: 3,
        playSounds: state.playSounds,
        rnd: Math.floor(Math.random() * 100)
      };
    case actionTypes.TOGGLE_MUTE:
      return {
        ...state,
        playSounds: !state.playSounds
      };
    case actionTypes.PAUSE:
      return {
        ...state,
        gameState: PAUSED
      };
    case actionTypes.UNPAUSE:
      return {
        ...state,
        gameState: PLAYING
      };
    case actionTypes.UNASSIGN_JOB:
      if (state.gameState !== PLAYING) {
        return state;
      }

      GeoJSONUpdateRequired = state.GeoJSONUpdateRequired;

      let unassignPermitted = true;

      newCouriers = state.couriers.map((courier: Courier) => {
        if (!courier.directions || !courier.directions.length) {
          // This courier has nothing to remove
          return courier;
        }

        if (courier.directions[0].jobId === action.jobId) {
          // This job is first in their list. It can't be removed as it'll mess up caching of
          //  waypoints
          unassignPermitted = false;

          return courier;
        }

        for (let i = 1; i < courier.directions.length; i++) {
          if (courier.directions[i].jobId === action.jobId) {
            // This courier owns this job, remove it
            let newCourier;

            [newCourier, GeoJSONUpdateRequired] = unassignJobFromCourier(
              courier,
              action.jobId
            );
    
            return newCourier;    
          }
        }

        return courier;
      });

      if (unassignPermitted) {
        // Set job as unassigned
        newJobs = state.jobs.map((job: Job) => {
          if (job.id === action.jobId) {
            return { ...job, status: UNASSIGNED };
          } else {
            return job;
          }
        });

        return {
          ...state,
          couriers: newCouriers,
          jobs: newJobs,
          GeoJSONUpdateRequired,
        };  
      }

      return {
        ...state,
        couriers: newCouriers,
        GeoJSONUpdateRequired,
      };  
    case actionTypes.SET_DIRECTIONS:
      if (state.gameState !== PLAYING) {
        return state;
      }

      GeoJSONUpdateRequired = state.GeoJSONUpdateRequired;

      newJobs = state.jobs;

      newCouriers = state.couriers.map((courier: Courier) => {
        if (courier.id === action.courierId) {
          GeoJSONUpdateRequired = true;

          // Set job as assigned
          newJobs = state.jobs.map((job: Job) => {
            if (job.id === action.jobId) {
              return { ...job, status: ASSIGNED, error: undefined };
            }

            return job;
          });

          return {
            ...courier,
            directions: courier.directions.concat({
              id: nanoid(),
              from: action.from,
              to: action.to,
              jobId: action.jobId,
              pickUpOrDropOff: action.pickUpOrDropOff,
              GeoJSON: undefined,
              GeoJSONRequested: false
            }),
          };
        } else {
          return courier;
        }
      });

      return {
        ...state,
        couriers: newCouriers,
        jobs: newJobs,
        GeoJSONUpdateRequired,
      };
    case actionTypes.GEO_JSON_REQUESTED:
      newCouriers = state.couriers.map((courier: Courier) => {
        if (courier.id === action.courierId) {
          const newDirections = courier.directions.map((dir: any) => {
            if (dir.id === action.directionsId) {
              return {
                ...dir,
                GeoJSONRequested: true,
              };
            } else {
              return dir;
            }
          });

          return {
            ...courier,
            directions: newDirections,
          };
        } else {
          return courier;
        }
      });

      return {
        ...state,
        couriers: newCouriers,
        GeoJSONUpdateRequired: false,
      };
    case actionTypes.POPULATE_GEOJSON:
      newCouriers = state.couriers.map((courier: Courier) => {
        if (courier.id === action.courierId) {
          const newDirections = courier.directions.map((dir: any) => {
            if (dir.id === action.directionsId) {
              return {
                ...dir,
                GeoJSON: action.GeoJSON,
              };
            } else {
              return dir;
            }
          });

          return {
            ...courier,
            directions: newDirections,
          };
        } else {
          return courier;
        }
      });

      return {
        ...state,
        couriers: newCouriers,
      };
    case actionTypes.REMOVE_FAILED_JOB:
      let filteredJobs = state.jobs;

      // Called when unable to get the polystring for directions.
      //  Removes the job from the courier and sets the job to unassigned.
      newCouriers = state.couriers.map((courier: Courier) => {
        if (courier.id === action.courierId) {
          let filteredDirections = courier.directions;

          // Find the directions in question
          const dir = filteredDirections.find((dir: any) => {
            return (dir.id === action.directionsId)
          });

          if (dir) {
            filteredJobs = state.jobs.filter((job: Job) => {
              // Bin this job completely, it probably can't be done
              return (dir.jobId !== job.id);
            });

            filteredDirections = filteredDirections.filter((d: any) => {
              // Filter out the direction for this job id
              return (d.jobId !== dir.jobId);
            });
          }

          return {
            ...courier,
            directions: filteredDirections,
          };
        } else {
          return courier;
        }
      });
  
      return {
        ...state,
        couriers: newCouriers,
        jobs: filteredJobs,
        GeoJSONUpdateRequired: false,
      };
    case actionTypes.END_GAME:
      return endGame(state, MAX_TICKS);
    case actionTypes.TICK:
      if (
        state.gameState === NOT_STARTED ||
        state.gameState === GAME_OVER ||
        state.gameState === PAUSED ||
        state.gameState.tick < 0
      ) {
        // Shouldn't happen, but just in case
        return state;
      }

      let tick = state.tick;

      tick++;

      if (tick >= MAX_TICKS) {
        // Game over
        if (state.playSounds) {
          bell.play();
        }

        return endGame(state, tick);
      }

      const jobIdsInProgress: string[] = [];
      const jobIdsToUnassign: string[] = [];
      const jobIdsComplete: string[] = [];
      GeoJSONUpdateRequired = state.GeoJSONUpdateRequired;
      let money: number = state.money;

      let noJobsCompleted = state.noJobsCompleted;

      newCouriers = state.couriers.map((courier: Courier) => {
        let annoyance = courier.annoyance;

        if (!courier.directions.length) {
          // No directions, nothing to do
          let mood = courier.mood;

          annoyance++;

          if (annoyance >= courier.annoyanceLimit) {
            // They're annoyed at not getting any work
            mood = moodChange(mood, -1);
            annoyance = 0;
          }

          return {
            ...courier,
            mood,
            annoyance
          };
        }

        annoyance = 0;

        // Check if the courier can take an initial step
        if (courier.coordinateNo === undefined) {
          if (courier.directions[0].GeoJSON) {
            // They have directions, so they can
            const coordinates =
              courier.directions[0].GeoJSON.coordinates;

            if (coordinates.length > 0) {
              return {
                ...courier,
                coordinateNo: 0,
                coordinates: [coordinates[0][0], coordinates[0][1]],
                distanceSoFar: 0
              };
            } else {
              console.log("+++++ ROUTE WITH NO STEPS (?)");
              console.dir(courier.directions[0].GeoJSON);

              if (courier.directions[0].pickUpOrDropOff === DROP_OFF) {
                jobIdsComplete.push(courier.directions[0].jobId);
              }

              if (courier.directions[0].pickUpOrDropOff === PICK_UP) {
                jobIdsInProgress.push(courier.directions[0].jobId);
              }

              const newDirections = courier.directions.slice(
                1,
                courier.directions.length
              );

              return {
                ...courier,
                directions: newDirections,
                coordinateNo: 0,
                distanceSoFar: 0,
                annoyance
              };
            }
          } else {
            // No step but no directions set yet, return
            return {
              ...courier,
              annoyance
            };
          }
        } else {
          // They have a step, see if it's the last for this direction
          const currentCoordinateNo = courier.coordinateNo;

          const coordinates =
            courier.directions[0].GeoJSON.coordinates;

          let potentialVolume: number = 0;
          let potentialMass: number = 0;

          // @todo - not a good variable name
          let potentialCourierJobIds = [...courier.jobsIdsInProgress];

          if (currentCoordinateNo + 1 >= coordinates.length) {
            // Set the courier location to where the job ended, not the last step in the directions,
            //  as they're not the same
            const courierNewLocation = courier.directions[0].to;

            // Directions complete
            let mood = courier.mood;

            if (courier.directions[0].pickUpOrDropOff === DROP_OFF) {
              jobIdsComplete.push(courier.directions[0].jobId);
              noJobsCompleted++;

              // Remove from courier's jobs in progress
              potentialCourierJobIds = potentialCourierJobIds.filter(
                (jobId) => !(jobId === courier.directions[0].jobId)
              );

              money += profit(courier.directions[0].jobId, state.jobs, tick);

              mood = moodChange(mood, 1);

              if (state.playSounds) {
                kaching.play();
              }
            }

            if (courier.directions[0].pickUpOrDropOff === PICK_UP) {
              // Check if they have room for this
              potentialCourierJobIds.push(courier.directions[0].jobId);

              state.jobs.forEach((job: Job) => {
                if (potentialCourierJobIds.includes(job.id)) {
                  potentialVolume += job.item.volume;
                  potentialMass += job.item.mass;
                }
              });

              if (
                potentialVolume > courier.volume ||
                potentialMass > courier.mass
              ) {
                // Job too big or too heavy, unassign it
                potentialCourierJobIds = [...courier.jobsIdsInProgress];

                const courierNewLocation = courier.directions[0].to;

                jobIdsToUnassign.push(courier.directions[0].jobId);

                [courier, GeoJSONUpdateRequired] = unassignOverweightJobFromCourier(
                  courier,
                  courier.directions[0].jobId
                );

                return {
                  ...courier,
                  coordinates: courierNewLocation,
                  coordinateNo: undefined,
                  mood: moodChange(courier.mood, -1)
                };
              }

              jobIdsInProgress.push(courier.directions[0].jobId);
            }

            const newDirections = courier.directions.slice(
              1,
              courier.directions.length
            );

            return {
              ...courier,
              coordinates: courierNewLocation,
              directions: newDirections,
              coordinateNo: undefined,
              mood,
              annoyance,
              jobsIdsInProgress: potentialCourierJobIds
            };
          } else {
            // Take the next step
            let distanceSoFar = courier.distanceSoFar;

            // Courier speed is dependent on mood
            const moodSpeed = (courier.speed / 2) + ((courier.speed / 20) * courier.mood);

            distanceSoFar += moodSpeed;

            // Find how far along the distances the courier has got
            let newCoordinateNo = getCoordinateNo(distanceSoFar, courier.directions[0].GeoJSON.distances);

            const newLocation = coordinates[newCoordinateNo];

            return {
              ...courier,
              coordinateNo: newCoordinateNo,
              coordinates: [newLocation[0], newLocation[1]],
              distanceSoFar,
              annoyance
            };
          }
        }
      });

      if (tick % (60 * MIN_MULTIPLIER) === 0) {
        newCouriers.forEach((courier: Courier) => {
          if (courier.added) {
            money -= courier.wages;
          }
        })
      }

      newJobs = [...state.jobs];

      // Get rid of completed jobs
      if (jobIdsComplete.length > 0) {
        newJobs = state.jobs.filter((job: Job) => {
          return !jobIdsComplete.includes(job.id);
        });
      }

      // Update status of newly in progress jobs
      if (jobIdsInProgress.length > 0) {
        newJobs = newJobs.map((job: Job) => {
          if (jobIdsInProgress.includes(job.id)) {
            return {
              ...job,
              status: IN_PROGRESS,
            };
          } else {
            return job;
          }
        });
      }

      // Unassign any jobs that don't have room for
      if (jobIdsToUnassign.length > 0) {
        newJobs = newJobs.map((job: Job) => {
          if (jobIdsToUnassign.includes(job.id)) {
            if (state.playSounds) {
              fail.play();
            }

            return {
              ...job,
              status: UNASSIGNED,
              error: TOO_BIG,
            };
          } else {
            return job;
          }
        });
      }

      newJobs = newJobs.filter((job: Job) => {
        const timedOut = (job.timeLimitTick && job.timeLimitTick < tick && job.status === UNASSIGNED);

        if (timedOut) {
          money -= job.fine;
        }

        return !timedOut;
      });

      let jobNo = state.jobNo;

      // Create new jobs as required
      state.customers.forEach((customer: Customer) => {
        if (customer.added) {
          // There is a limit to how many jobs can be created, count how many there are
          let noJobs = newJobs.reduce((total: number, job: Job) => {
            if (job.customerId === customer.id) {
              total++;
            }

            return total;
          }, 0);

          if (noJobs < customer.jobNoLimit) {
            // This customer hasn't hit their job limit yet

            customer.jobRules.forEach(jobRule => {
              // If there are no tick start and end rules, or if there are and it's during them, try to create a job
              if (!jobRule.startTick || (tick >= jobRule.startTick && tick <= jobRule.endTick)) {
                let newJob: Job | undefined = undefined;

                if (jobRule.type === "scheduled") {
                  if (state.tick % jobRule.schedule === 0) {
                    newJob = createJob(customer, locations, jobNo, jobRule, state.tick);
                  }
                } else if (jobRule.type === "random") {
                  // Using Math.random() made the reducer go wrong on the second run.
                  //  Day added so the hash results aren't always identical
                  if (Math.floor(hashCode(tick.toString() + state.rnd) % jobRule.schedule) === 0) {
                    newJob = createJob(customer, locations, jobNo, jobRule, state.tick);
                  }
                }

                if (newJob) {
                  newJobs.push(newJob);

                  if (state.playSounds) {
                    ping.play();
                  }

                  jobNo++;
                  noJobs++;
                }
              }
            })
          }
        }
      });

      let newState = {
        ...state,
        couriers: newCouriers,
        jobs: newJobs,
        GeoJSONUpdateRequired,
        money,
        noJobsCompleted,
        tick,
        jobNo
      };

      return newState;
    case actionTypes.REORDER_DIRECTIONS:
      if (state.gameState !== PLAYING) {
        return state;
      }

      let courier = state.couriers.find((courier: Courier) => {
        return courier.id === action.courierId;
      });

      GeoJSONUpdateRequired = state.GeoJSONUpdateRequired;

      if (courier) {
        let directions = [...courier.directions];

        let moveable = false;

        let sourceDirectionIndex: number | undefined;

        // Find the source direction index
        for (let i = 0; i < directions.length; i++) {
          if (directions[i].id === action.sourceDirectionId) {
            sourceDirectionIndex = i;
          }
        }

        if (sourceDirectionIndex === undefined) {
          // The source direction don't exist, something has changed in the
          //  interim perhaps?
          return state;
        }

        if (sourceDirectionIndex !== action.sourceDirectionIndex) {
          // The provided source direction index doesn't match the index
          //  where the direction id currently is
          return state;
        }

        // There's no way to confirm the destination index as the library
        //  doesn't provide the destination id

        const sourceDirection = directions[sourceDirectionIndex];

        let pairDirectionIndex: number | undefined;

        // Find the other direction that has the same job id (if any)
        for (let i = 0; i < directions.length; i++) {
          if (
            directions[i].jobId === sourceDirection.jobId &&
            directions[i].id !== sourceDirection.id
          ) {
            pairDirectionIndex = i;
          }
        }

        if (pairDirectionIndex === undefined) {
          // The pair of the direction (presumably pick up, as drop off can't be
          //  done beforehand) isn't there so moving it up is fine
          moveable = true;
        } else if (directions[pairDirectionIndex].pickUpOrDropOff === PICK_UP) {
          if (pairDirectionIndex < action.destinationDirectionIndex) {
            // The user is not attempting to move a direction before its pair.
            //  The source direction must be a drop off (as pick ups can't be
            //  be before their pair) therefore the move is allowed.
            moveable = true;
          }
        } else if (pairDirectionIndex > action.destinationDirectionIndex) {
          moveable = true;
        }

        if (moveable) {
          GeoJSONUpdateRequired = true;

          courier.coordinateNo = swapLocations(
            courier.coordinateNo,
            courier.coordinates,
            directions,
            sourceDirectionIndex,
            action.destinationDirectionIndex
          );

          const [removed] = directions.splice(sourceDirectionIndex, 1);
          directions.splice(action.destinationDirectionIndex, 0, removed);

          newCouriers = state.couriers.map((c: Courier) => {
            return c.id === courier.id
              ? // ? {
                //     id: courier.id,
                //     name: courier.name,
                //     coordinates: courier.coordinates,
                //     directions,
                //     coordinateNo: courier.coordinateNo,
                //     added: courier.added,
                //     type: courier.type,
                //   }
                {
                  ...courier,
                  directions,
                }
              : c;
          });

          return {
            ...state,
            couriers: newCouriers,
            GeoJSONUpdateRequired,
          };
        }

        return state;
      }

      return state;
    case actionTypes.ADD_CUSTOMER:
      if (state.gameState !== PLAYING) {
        return state;
      }

      newJobs = [...state.jobs];
      let jNo = state.jobNo;

      const newCustomers = state.customers.map((customer: Customer) => {
        if (customer.id === action.customerId) {
          // Add a job for this customer so to save waiting around
          const jobRule =  customer.jobRules[0];

          let newJob: Job = createJob(customer, locations, jNo, jobRule, state.tick);
    
          newJobs.push(newJob);
    
          if (state.playSounds) {
            ping.play();
          }
    
          jNo++;
  
          return {
            ...customer,
            added: true,
          };
        }

        return customer;
      });

      return {
        ...state,
        customers: newCustomers,
        jobs: newJobs,
        jobNo: jNo
      };
    case actionTypes.ADD_COURIER:
      if (state.gameState !== PLAYING) {
        return state;
      }

      return {
        ...state,
        couriers: state.couriers.map((courier: Courier) => {
          if (courier.id === action.courierId) {
            return {
              ...courier,
              added: true,
            };
          }

          return courier;
        }),
      };
    default:
      return state;
  }
};

const getCoordinateNo = (distanceSoFar: number, distances: number[]) => {
  for (let i = 0; i < distances.length; i++) {
    if (distances[i] > distanceSoFar) {
      return i - 1;
    }
  }

  // @todo - this might make the 'coordinateNo + 1' code above a bit wrong
  return distances.length - 1;
}

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
  }

  return hash;
}